import { DeleteOutlined, EyeOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Col, PageHeader, Row, Space, Statistic, Table, Tag } from 'antd';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { deleteSignupRequest, listSignupRequests, selectSignupRequest } from '../redux/features/signup-requests/signup-requests.slice';
import { useAppDispatch, useAppSelector } from '../redux/hook';

const SignupRequests = () => {
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const { signupRequests: list, stats } = useAppSelector((state) => state.signupRequests);

  useEffect(() => {
    if (dispatch) dispatch(listSignupRequests());

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleView = (item: any) => {
    dispatch(selectSignupRequest(item));
    navigate(`/signup-requests/${item._id}`);
  };

  const handleReviewClick = (item: any) => {
    dispatch(selectSignupRequest(item));
    navigate(`/signup-requests/${item._id}`);
  };

  const handleDeleteClick = (item: any) => {
    dispatch(deleteSignupRequest(item));
  };

  const columns = [
    {
      title: '#',
      render: (_text: string, _record: any, index: number) => {
        return <span>{index + 1}</span>;
      },
    },
    {
      title: 'Applicant',
      render: (_text: string, record: any, _index: number) => {
        return (
          <span>
            {record.policyHolder.fullnames} {record.policyHolder.surname}
          </span>
        );
      },
    },
    {
      title: 'Email Address',
      render: (_text: string, record: any, _index: number) => {
        return <span>{record.policyHolder.emailAddress}</span>;
      },
    },
    {
      title: 'Phone Number',
      render: (_text: string, record: any, _index: number) => {
        return <span>{record.policyHolder.phoneNumber}</span>;
      },
    },
    {
      title: 'Selected Plan',
      render: (_text: string, record: any, _index: number) => {
        return <span>{record.selectedPlan?.name ?? <i>Applicant has not selected yet</i>}</span>;
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (text: string) => {
        return <Tag>{text}</Tag>;
      },
    },
    {
      title: 'Progress',
      render: (_text: string, record: any, _index: number) => {
        return <Tag>Step {record.currentStep}</Tag>;
      },
    },
    {
      title: 'Actions',
      render: (text: string, record: any, index: number) => {
        return (
          <Space>
            <Button onClick={() => handleView(record)} size='small'>
              <EyeOutlined /> View
            </Button>
            {record.status === 'Submitted' && (
              <Button onClick={() => handleReviewClick(record)} type='primary' size='small'>
                <SearchOutlined /> Review
              </Button>
            )}
            {record.status === 'Draft' && (
              <Button onClick={() => handleDeleteClick(record._id)} type='default' size='small' style={{ color: 'red' }}>
                <DeleteOutlined /> Delete
              </Button>
            )}
          </Space>
        );
      },
    },
  ];

  return (
    <>
      <PageHeader className='site-page-header' title='Signup Requests' extra={[]}>
        <Row>
          <Col span={20}>
            <Space size={72}>
              <Statistic title='Drafts' value={stats.draftsCount} />
              <Statistic title='Submitted' value={stats.submittedCount} />
              <Statistic title='Reviewed' value={stats.reviewedCount} />
              <Statistic title='Accepted' value={stats.acceptedCount} />
              <Statistic title='Rejected' value={stats.rejectedCount} />
              <Statistic title='Archived' value={stats.archivedCount} />
            </Space>
          </Col>
        </Row>
      </PageHeader>
      <div className='signup-requests-page page'>
        <div className='container'>
          <Table
            columns={columns}
            dataSource={list}
            size='small'
            pagination={{
              position: ['topRight', 'bottomRight'],
              pageSize: 20,
              showSizeChanger: false,
            }}
          />
        </div>
      </div>
    </>
  );
};

export default SignupRequests;
