import { Layout } from 'antd';
import AppBar from '../components/appbar';
import SidePanel from '../components/sidepanel';
import { useAppSelector } from '../redux/hook';

const { Content, Header, Sider } = Layout;

const ProtectedLayout = ({ children }: any) => {
  const { theme } = useAppSelector((state) => state.preferences);

  return (
    <Layout>
      <Header>
        <AppBar />
      </Header>
      <Layout className={`${theme.toLowerCase()}`}>
        <Sider>
          <SidePanel />
        </Sider>
        <Content>{children}</Content>
      </Layout>
    </Layout>
  );
};

export default ProtectedLayout;
