import { Col, PageHeader, Row, Statistic, Table, Input, Form, Empty, Spin, Button, Modal } from 'antd';
import { useEffect, useState } from 'react';
import { addFAQ, getFAQs, searchFAQs } from '../redux/features/faqs/faqs.slice';
import { useAppDispatch, useAppSelector } from '../redux/hook';
import { formatUCTtoISO } from '../utils/formatters';
import searchImage from '../assets/images/search.png';
import { PlusOutlined } from '@ant-design/icons';
import { IFaq } from '../models/faq.model';

const Search = Input.Search;
const FormItem = Form.Item;
const TextArea = Input.TextArea;

const FAQs = () => {
  const dispatch = useAppDispatch();
  const [visible, setVisible] = useState(false);
  const [newFAQ, setNewFAQ] = useState<IFaq>({
    question: '',
    answer: '',
  });
  const { faqs, count, status } = useAppSelector((state) => state.faqs);

  const [search, setSearch] = useState('');

  useEffect(() => {
    dispatch(getFAQs());

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = [
    {
      title: 'Question',
      dataIndex: 'question',
    },
    {
      title: 'Answer',
      dataIndex: 'answer',
    },
    {
      title: 'Date Created',
      dataIndex: 'created_at',
      render: (text: string) => <span>{formatUCTtoISO(text)}</span>,
    },
  ];

  const handlePageChange = (page: number, pageSize: number) => {
    dispatch(getFAQs());
  };

  useEffect(() => {
    if (status === 'FAQ created successfully' && visible && newFAQ.question.length > 0 && newFAQ.answer.length > 0) {
      setNewFAQ({
        question: '',
        answer: '',
      });

      setVisible(false);
    }

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  const handleSubmit = () => {
    if (newFAQ.question.length > 0 && newFAQ.answer.length > 0) dispatch(addFAQ(newFAQ));
  };

  return (
    <>
      <PageHeader
        className='site-page-header'
        title='Frequently Asked Questions'
        extra={[
          <Button key='1' onClick={() => setVisible(true)}>
            <PlusOutlined /> Add FAQ
          </Button>,
        ]}>
        <Row gutter={16}>
          <Col span={2}>
            <Statistic title='Total FAQs' value={count} />
          </Col>
          <Col span={3}>
            <Statistic title='Selected FAQs' value={faqs.length} />
          </Col>
        </Row>
      </PageHeader>
      <Form layout='vertical' style={{ width: '100%', borderBottom: '1px solid #ddd', padding: '2rem 1rem 3rem 1rem' }}>
        <Form.Item style={{ marginBottom: '0', width: '27%' }}>
          <p style={{ marginBottom: '.5rem', textTransform: 'uppercase', letterSpacing: '.2rem' }}>Search</p>
          <Search
            allowClear
            value={search}
            placeholder='Enter keyword...'
            onChange={(event: any) => setSearch(event.target.value)}
            onSearch={(value: string) => value.length > 0 && dispatch(searchFAQs(value))}
          />
        </Form.Item>
      </Form>
      <div className='faqs-page page' style={{ minHeight: '67vh' }}>
        <div className='container'>
          <Spin tip={status} spinning={status === 'Deleting selected FAQs'}>
            {faqs.length > 0 ? (
              <Table
                columns={columns}
                dataSource={faqs}
                size='small'
                pagination={{
                  position: ['bottomRight'],
                  onChange: handlePageChange,
                  total: count,
                  pageSize: faqs.length,
                }}
              />
            ) : (
              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <Empty
                  image={searchImage}
                  imageStyle={{
                    height: 420,
                  }}
                  description={
                    <h3 style={{ fontWeight: 'normal', margin: '2rem 0 0 0' }}>
                      Search transactions by references and descriptions...
                    </h3>
                  }
                />
              </div>
            )}
          </Spin>
        </div>
        <Modal
          title='Post a new FAQ'
          centered
          destroyOnClose={true}
          visible={visible}
          onOk={handleSubmit}
          onCancel={() => setVisible(false)}
          width={1000}>
          <Form layout='vertical'>
            <FormItem label='Question'>
              <TextArea
                value={newFAQ.question}
                rows={1}
                onChange={(event: any) => setNewFAQ({ ...newFAQ, question: event.target.value })}
              />
            </FormItem>
            <FormItem label='Answer'>
              <TextArea
                value={newFAQ.answer}
                rows={4}
                onChange={(event: any) => setNewFAQ({ ...newFAQ, answer: event.target.value })}
              />
            </FormItem>
          </Form>
        </Modal>
      </div>
    </>
  );
};

export default FAQs;
