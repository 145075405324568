import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { CLIENT_SERVICE_URL } from '../../../app-constants';
import { getAuthHeader } from '../../helpers';

// Define a type for the slice state
interface ClaimsState {
  claims: any[];
  selectedClaim?: any;
  submittedCount: number;
  underReviewCount: number;
  approvedCount: number;
  rejectedCount: number;
  processingCount: number;
  paidCount: number;
  totalClaimCount: number;
  currentPage: number;
  totalPages: number;
}

// Define the initial state using that type
const initialState: ClaimsState = {
  claims: [],
  submittedCount: 0,
  underReviewCount: 0,
  approvedCount: 0,
  rejectedCount: 0,
  processingCount: 0,
  paidCount: 0,
  totalClaimCount: 0,
  currentPage: 0,
  totalPages: 0,
};

export const getClaims = createAsyncThunk('claims/getClaims', async (page: string = '0') => {
  try {
    const response = await axios.get(`${CLIENT_SERVICE_URL}claims?p=${page}`, {
      headers: getAuthHeader(),
    });

    if (response.status === 200) {
      return response.data;
    } else {
      console.log(response);
      return false;
    }
  } catch (error) {
    console.error(error);
    return false;
  }
});

export const getClaimDetails = createAsyncThunk('claims/getClaimDetails', async (claimId: string) => {
  try {
    const response = await axios.get(`${CLIENT_SERVICE_URL}claims/${claimId}`, {
      headers: getAuthHeader(),
    });

    if (response.status === 200) {
      return response.data;
    } else {
      console.log(response);
      return false;
    }
  } catch (error) {
    console.error(error);
    return false;
  }
});

export const claimsSlice = createSlice({
  name: 'claims',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    hydrateClaims(state: any, action: any) {
      state.status = 'idle';
      state.currentPage = action.payload.currentPage;
      state.totalPages = action.payload.totalPages;

      state.claims = action.payload.claims;
      state.totalClaimCount = action.payload.totalClaimCount;
      state.submittedCount = action.payload.submittedClaimCount;
      state.underReviewCount = action.payload.underReviewClaimCount;
      state.approvedCount = action.payload.approvedClaimCount;
      state.rejectedCount = action.payload.rejectedClaimCount;
      state.processingCount = action.payload.processingClaimCount;
      state.paidCount = action.payload.paidClaimCount;
    },
    selectClaim(state: any, action: any) {
      state.selectedClaim = action.payload;
    },
  },
  extraReducers: (builder: any) => {
    builder
      .addCase(getClaimDetails.pending, (state: any) => {
        state.status = 'Fetching Claim Details';
      })
      .addCase(getClaimDetails.rejected, (state: any, payload: any) => {
        state.status = 'idle';
      })
      .addCase(getClaimDetails.fulfilled, (state: any, action: any) => {
        state.status = 'idle';

        state.selectedClaim = action.payload;
      })
      .addCase(getClaims.pending, (state: any) => {
        state.status = 'Fetching claims';
      })
      .addCase(getClaims.rejected, (state: any, payload: any) => {
        state.status = 'idle';
      })
      .addCase(getClaims.fulfilled, (state: any, action: any) => {
        state.status = 'idle';
        state.currentPage = action.payload.currentPage;
        state.totalPages = action.payload.totalPages;

        let updatedList = [...action.payload.claims];
        updatedList.map((item) => (item.key = item._id));
        state.claims = updatedList;

        state.totalClaimCount = action.payload.totalClaimCount;
        state.submittedCount = action.payload.submittedClaimCount;
        state.underReviewCount = action.payload.underReviewClaimCount;
        state.approvedCount = action.payload.approvedClaimCount;
        state.rejectedCount = action.payload.rejectedClaimCount;
        state.processingCount = action.payload.processingClaimCount;
        state.paidCount = action.payload.paidClaimCount;
      });
  },
});

export const { hydrateClaims, selectClaim } = claimsSlice.actions;

export default claimsSlice.reducer;
