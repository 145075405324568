import { Avatar } from "antd"
import "./style.scss"
import { useAppSelector } from "../../redux/hook"
import { getInitials } from "../../utils/helpers"

const AppBar = () => {
	const { theme } = useAppSelector((state) => state.preferences)
	const { user } = useAppSelector((state) => state.auth)

	return (
		<div className={`app-bar ${theme.toLowerCase()}`}>
			<div></div>
			<p style={{ marginLeft: "1rem" }}>Somdaka Funeral Services - Administration Portal</p>
			<div style={{ display: "flex", alignItems: "center" }}>
				<p style={{ margin: "0", padding: "0" }}>{user.emailAddress}</p>
				<p style={{ margin: "0 1rem", padding: "0" }}>
					<Avatar style={{ backgroundColor: "#cc6b27", letterSpacing: "1px" }}>{getInitials(`${user.fullnames}`)}</Avatar>
				</p>
			</div>
		</div>
	)
}

export default AppBar
