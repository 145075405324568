import { Button, Col, Collapse, DatePicker, Form, Input, Modal, PageHeader, Row, Select, Space, Upload } from 'antd';
import { useAppDispatch } from '../redux/hook';
import { useEffect, useState } from 'react';
import { getClaimDetails } from '../redux/features/claims/claims.slice';
import { useLocation } from 'react-router-dom';
import { CloseOutlined, FileAddOutlined, SaveOutlined, UploadOutlined } from '@ant-design/icons';
import { RcFile } from 'antd/lib/upload';

const FormItem = Form.Item;
const Panel = Collapse.Panel;

interface IUploader {
  key: string;
  name: string;
}

const CreateClaim = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();

  const [uploaderVisible, setUploaderVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const [name, setName] = useState('');

  useEffect(() => {
    const claimId = location.pathname.split('/').pop();

    if (claimId) dispatch(getClaimDetails(claimId));

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showUploader = ({ key, name }: IUploader) => {
    setName(name);

    setUploaderVisible(true);
  };

  const handleOk = () => {
    setConfirmLoading(true);
    setTimeout(() => {
      setUploaderVisible(false);
      setConfirmLoading(false);
    }, 5000);
  };

  const handleCancel = () => {
    console.log('Clicked cancel button');
    setUploaderVisible(false);
  };

  return (
    <>
      <PageHeader
        className='site-page-header'
        title={`New Claim`}
        subTitle='Create a new claim to submit for review'
        extra={[
          <Button danger>
            <CloseOutlined /> Cancel
          </Button>,
          <Button type='primary'>
            <SaveOutlined /> Submit Claim
          </Button>,
        ]}
      />
      <div className='create-claim-page page'>
        <div className='container'>
          <Form layout='vertical'>
            <Collapse accordion defaultActiveKey='Deceased' bordered={false} expandIconPosition='left'>
              <Panel
                showArrow={true}
                key='Deceased'
                header='Particulars of the Deceased member'
                extra={
                  <Space>
                    <Button
                      size='small'
                      type='link'
                      onClick={(event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
                        event.stopPropagation();
                        showUploader({ key: 'death_certificate', name: 'Death Certificate' });
                      }}>
                      <UploadOutlined /> Death Certificate
                    </Button>
                    <Button
                      size='small'
                      type='link'
                      onClick={(event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
                        event.stopPropagation();
                        showUploader({ key: 'burial_order', name: 'Burial Order' });
                      }}>
                      <UploadOutlined /> Burial Order
                    </Button>
                    <Button
                      size='small'
                      type='link'
                      onClick={(event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
                        event.stopPropagation();
                        showUploader({ key: 'bi_1663', name: 'Notification of Death (BI-1663)' });
                      }}>
                      <UploadOutlined /> Notification of Death
                    </Button>
                    <Button
                      size='small'
                      type='link'
                      onClick={(event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
                        event.stopPropagation();
                        showUploader({ key: 'deceased_identification', name: "Deceased's ID or Passport" });
                      }}>
                      <UploadOutlined /> ID / Passport
                    </Button>
                  </Space>
                }>
                <Row gutter={16}>
                  <Col span={6}>
                    <FormItem label='Full Names'>
                      <Input />
                    </FormItem>
                  </Col>
                  <Col span={4}>
                    <FormItem label='Surname'>
                      <Input />
                    </FormItem>
                  </Col>
                  <Col span={4}>
                    <FormItem label='Identification Type'>
                      <Select>
                        <Select.Option value='South African ID'>South African ID Number</Select.Option>
                        <Select.Option value='Passport'>Passport Number</Select.Option>
                      </Select>
                    </FormItem>
                  </Col>
                  <Col span={4}>
                    <FormItem label='Identification Number'>
                      <Input />
                    </FormItem>
                  </Col>
                  <Col span={3}>
                    <FormItem label='Date of Birth'>
                      <DatePicker />
                    </FormItem>
                  </Col>
                  <Col span={3}>
                    <FormItem label='Date of Death'>
                      <DatePicker />
                    </FormItem>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={6}>
                    <FormItem label='Exact Cause of Death'>
                      <Input />
                    </FormItem>
                  </Col>
                  <Col span={4}>
                    <FormItem label='Policy Number'>
                      <Input />
                    </FormItem>
                  </Col>
                  <Col span={4}>
                    <FormItem label='Gender'>
                      <Select>
                        <Select.Option value='Male'>Male</Select.Option>
                        <Select.Option value='Female'>Female</Select.Option>
                      </Select>
                    </FormItem>
                  </Col>
                  <Col span={4}>
                    <FormItem label='Marital Status'>
                      <Select>
                        <Select.Option value='Single'>Single</Select.Option>
                        <Select.Option value='Married'>Married</Select.Option>
                        <Select.Option value='Divorced'>Divorced</Select.Option>
                        <Select.Option value='Widow'>Widow</Select.Option>
                        <Select.Option value='Widower'>Widower</Select.Option>
                      </Select>
                    </FormItem>
                  </Col>
                </Row>
              </Panel>
              <Panel
                showArrow={true}
                key='Claimant'
                header='Particulars of the Claimant'
                extra={
                  <Space>
                    <Button
                      size='small'
                      type='link'
                      onClick={(event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
                        event.stopPropagation();
                        showUploader({ key: 'proof_of_bank_account', name: "Claimant's Proof of Banking" });
                      }}>
                      <UploadOutlined /> Proof of Banking
                    </Button>
                    <Button
                      size='small'
                      type='link'
                      onClick={(event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
                        event.stopPropagation();
                        showUploader({ key: 'claimant_identification', name: "Claimant' ID or Passport" });
                      }}>
                      <UploadOutlined /> ID / Passport
                    </Button>
                  </Space>
                }>
                <Row gutter={16}>
                  <Col span={4}>
                    <FormItem label='Full Names'>
                      <Input />
                    </FormItem>
                  </Col>
                  <Col span={4}>
                    <FormItem label='Surname'>
                      <Input />
                    </FormItem>
                  </Col>
                  <Col span={4}>
                    <FormItem label='Identification Type'>
                      <Select>
                        <Select.Option value='South African ID'>South African ID Number</Select.Option>
                        <Select.Option value='Passport'>Passport Number</Select.Option>
                      </Select>
                    </FormItem>
                  </Col>
                  <Col span={4}>
                    <FormItem label='Identification Number'>
                      <Input />
                    </FormItem>
                  </Col>
                  <Col span={4}>
                    <FormItem label='Policy Number'>
                      <Input />
                    </FormItem>
                  </Col>
                  <Col span={4}>
                    <FormItem label='Claim Type'>
                      <Select>
                        <Select.Option value='Cash'>Cash</Select.Option>
                        <Select.Option value='Service'>Service</Select.Option>
                      </Select>
                    </FormItem>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={4}>
                    <FormItem label='Bank Name'>
                      <Input />
                    </FormItem>
                  </Col>
                  <Col span={4}>
                    <FormItem label='Branch Name'>
                      <Input />
                    </FormItem>
                  </Col>
                  <Col span={4}>
                    <FormItem label='Branch Code'>
                      <Input />
                    </FormItem>
                  </Col>
                  <Col span={4}>
                    <FormItem label='Account Holder'>
                      <Input />
                    </FormItem>
                  </Col>
                  <Col span={4}>
                    <FormItem label='Account Number'>
                      <Input />
                    </FormItem>
                  </Col>
                  <Col span={4}>
                    <FormItem label='Account Type'>
                      <Select>
                        <Select.Option value='Cheque'>Cheque Account</Select.Option>
                        <Select.Option value='Savings'>Savings Account</Select.Option>
                      </Select>
                    </FormItem>
                  </Col>
                </Row>
              </Panel>
            </Collapse>
          </Form>
        </div>
        <Modal
          title='Upload a file'
          visible={uploaderVisible}
          onOk={handleOk}
          confirmLoading={confirmLoading}
          footer={false}
          onCancel={handleCancel}>
          {/* <p>{uploaderText}</p> */}
          <Upload
            action='uploadUrl'
            name='files'
            showUploadList={false}
            beforeUpload={(file: RcFile) => {
              // handleFileUpload(file);
              return false;
            }}>
            <Button type='primary' style={{ margin: '0' }}>
              <FileAddOutlined /> Upload {name}
            </Button>
          </Upload>
        </Modal>
      </div>
    </>
  );
};

export default CreateClaim;
