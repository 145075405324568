import { Link, useLocation, useNavigate } from "react-router-dom"
import "./style.scss"
import { useAppDispatch, useAppSelector } from "../../redux/hook"
import { signOut } from "../../redux/features/auth/auth.slice"
import { useEffect } from "react"
import { IRoute, ROUTES } from "../../app-constants"
import { ThinDivider } from "../dividers/thinDivider"

const SidePanel = () => {
	const { theme } = useAppSelector((state) => state.preferences)
	const { signInRequired, isAdmin } = useAppSelector((state) => state.auth)

	const location = useLocation()
	const navigate = useNavigate()
	const dispatch = useAppDispatch()

	useEffect(() => {
		if (navigate) if (signInRequired) navigate("/login")

		return () => {}
	}, [signInRequired, navigate])

	const handleSignOut = () => {
		dispatch(signOut())
	}

  const activeRoutes = ROUTES.filter((route) => route.active === true).sort((a, b) => a.group - b.group);

	const adminRoutes = activeRoutes;

	const routes = activeRoutes.filter((route) => route.requiresAdmin === false)

  const routesToDisplay = isAdmin ? adminRoutes : routes;

	return (
		<div className={`side-panel ${theme.toLowerCase()}`}>
			<div className='side-panel-body'>
				<ul>
					{routesToDisplay.map((route: IRoute, index: number, array: IRoute[]) => (
							<>
								<Link to={route.url} key={route.key}>
									<li key={route.key} className={`${theme.toLowerCase()} ${location.pathname.includes(route.key) ? "active" : ""}`}>
										{route.name}
									</li>
								</Link>
								{route.group !== array[index + 1]?.group && <ThinDivider />}
							</>
						))}
					<li onClick={handleSignOut} className='btn-sign-out'>
						Sign Out
					</li>
				</ul>
			</div>
		</div>
	)
}

export default SidePanel
