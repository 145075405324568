import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { CLIENT_SERVICE_URL } from '../../../app-constants';
import { ITermsOfService } from '../../../models/terms.model';
import { getAuthHeader } from '../../helpers';

// Define a type for the slice state
interface TermsOfServiceState {
  termsOfService: ITermsOfService[];
  selectedTermsOfService: ITermsOfService | null;
  count: number;
  currentPage: number;
  totalPages: number;
  status: string;
}

// Define the initial state using that type
const initialState: TermsOfServiceState = {
  termsOfService: [],
  selectedTermsOfService: null,
  count: 0,
  currentPage: 0,
  totalPages: 0,
  status: 'idle',
};

export const addTermsOfService = createAsyncThunk('terms-of-service/add', async (payload: ITermsOfService) => {
  try {
    const response = await axios.post(`${CLIENT_SERVICE_URL}terms`, payload, {
      headers: getAuthHeader(),
    });

    if (response.status === 200) {
      return response.data;
    } else {
      console.log(response);
      return false;
    }
  } catch (error) {
    console.error(error);
    return false;
  }
});

export const getTermsOfService = createAsyncThunk('terms-of-service/list', async () => {
  try {
    const response = await axios.get(`${CLIENT_SERVICE_URL}terms`, {
      headers: getAuthHeader(),
    });

    if (response.status === 200) {
      return response.data;
    } else {
      console.log(response);
      return false;
    }
  } catch (error) {
    console.error(error);
    return false;
  }
});

export const getTermsOfServiceById = createAsyncThunk('terms-of-service/get', async (id: string) => {
  try {
    const response = await axios.get(`${CLIENT_SERVICE_URL}terms/${id}`, {
      headers: getAuthHeader(),
    });

    if (response.status === 200) {
      return response.data;
    } else {
      console.log(response);
      return false;
    }
  } catch (error) {
    console.error(error);
    return false;
  }
});

export const termsOfServiceSlice = createSlice({
  name: 'terms-of-service',
  initialState,
  reducers: {
    hydrateTermsOfService(state: any, action: any) {
      state.faqs = action.payload.faqs;
      state.currentPage = action.payload.currentPage;
      state.totalPages = action.payload.totalPages;
    },
  },
  extraReducers: (builder: any) => {
    builder
      .addCase(addTermsOfService.pending, (state: any) => {
        state.status = 'Adding new Terms of Service';
      })
      .addCase(addTermsOfService.rejected, (state: any) => {
        state.status = 'idle';
      })
      .addCase(addTermsOfService.fulfilled, (state: any, action: any) => {
        state.status = action.payload.message;

        let updatedTermsOfService = [...state.faqs, action.payload.termsOfService];
        state.termsOfService = updatedTermsOfService;
      })
      .addCase(getTermsOfService.pending, (state: any) => {
        state.status = 'Fetching Terms of Service';
      })
      .addCase(getTermsOfService.rejected, (state: any) => {
        state.status = 'idle';
      })
      .addCase(getTermsOfService.fulfilled, (state: any, action: any) => {
        state.status = 'idle';
        state.count = action.payload.count;

        let updatedItems = [...action.payload.terms];
        updatedItems.map((item) => (item.key = item._id));

        state.termsOfService = updatedItems;
        state.currentPage = action.payload.currentPage;
        state.totalPages = action.payload.totalPages;
      })
      .addCase(getTermsOfServiceById.pending, (state: any) => {
        state.status = 'Fetching selected Terms of Service';
      })
      .addCase(getTermsOfServiceById.rejected, (state: any) => {
        state.status = 'idle';
      })
      .addCase(getTermsOfServiceById.fulfilled, (state: any, action: any) => {
        state.status = 'idle';

        state.selectedTermsOfService = action.payload;
      });
  },
});

export const { hydrateTermsOfService } = termsOfServiceSlice.actions;

export default termsOfServiceSlice.reducer;
