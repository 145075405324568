import { Col, PageHeader, Row, Space, Statistic, Timeline } from 'antd';
import { useAppDispatch, useAppSelector } from '../redux/hook';
import { useEffect } from 'react';
import { getClaimDetails } from '../redux/features/claims/claims.slice';
import { useLocation, useNavigate } from 'react-router-dom';
import { formatToMoneyWithCurrency } from '../utils/formatters';
import { CommentOutlined } from '@ant-design/icons';

const ClaimDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const {
    selectedClaim: { claimant, policyHolder, policyNumber, coverAmount, claimAmount },
  } = useAppSelector((state) => state.claims);

  useEffect(() => {
    const claimId = location.pathname.split('/').pop();

    if (claimId) dispatch(getClaimDetails(claimId));

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <PageHeader className='site-page-header' title={`Claim Details`} onBack={() => navigate(-1)}>
        <Row>
          <Col span={20}>
            <Space size={72}>
              <Statistic title='Policy Number' value={policyNumber} />
              <Statistic title='Cover Amount' value={formatToMoneyWithCurrency(coverAmount)} />
              <Statistic title='Policy Holder' value={policyHolder} />
              <Statistic title='Claim Amount' value={formatToMoneyWithCurrency(claimAmount)} />
              <Statistic title='Claimant' value={claimant} />
            </Space>
          </Col>
        </Row>
      </PageHeader>
      <div className='claims-page page'>
        <div className='container'>
          <Row>
            <Col span={9}>
              <h3>Recent Activity</h3>
              <Timeline style={{ margin: '1.5rem 0 0 1.5rem' }}>
                <Timeline.Item color='green'>
                  <p style={{ marginBottom: '0' }}>User submitted claim</p>
                  <small>
                    <i>2022-08-02</i>
                  </small>
                </Timeline.Item>
                <Timeline.Item color='blue'>
                  <p style={{ marginBottom: '0' }}>Tokozile Vilakazi reviewed claim</p>
                  <small>
                    <i>2022-08-03</i>
                  </small>
                </Timeline.Item>
                <Timeline.Item color='green'>
                  <p style={{ marginBottom: '0' }}>Buhle Sibiya approved claim</p>
                  <small>
                    <i>2022-08-03</i>
                  </small>
                </Timeline.Item>
                <Timeline.Item color='gray'>
                  <p style={{ marginBottom: '0' }}>Wendy Somdaka viewed claim</p>
                  <small>
                    <i>2022-08-03</i>
                  </small>
                </Timeline.Item>
                <Timeline.Item color='green'>
                  <p style={{ marginBottom: '0' }}>Wendy Somdaka processed payment</p>
                  <small>
                    <i>2022-08-04</i>
                  </small>
                </Timeline.Item>
              </Timeline>
            </Col>
            <Col span={15}>
              <h3>Remarks / Comments</h3>
              <Timeline style={{ margin: '1.5rem 0 0 1.5rem' }}>
                <Timeline.Item dot={<CommentOutlined />} >
                  <p style={{ marginBottom: '0' }}>All documents were submitted by the client. Scanned and uploaded.</p>
                  <small>
                    <i>2022-08-03</i> - <b>Tokozile Vilakazi</b>
                  </small>
                </Timeline.Item>
                <Timeline.Item dot={<CommentOutlined />} >
                  <p style={{ marginBottom: '0' }}>Claim is for a dependent under 14 years, therefore claim amount is 50% of the cover amount.</p>
                  <small>
                    <i>2022-08-03</i> - <b>Buhle Sibiya</b>
                  </small>
                </Timeline.Item>
                <Timeline.Item dot={<CommentOutlined />} >
                  <p style={{ marginBottom: '0' }}>Proof of payment sent to client by email. Funds should reflect by Friday, 2022/08/06.</p>
                  <small>
                    <i>2022-08-04</i> - <b>Wendy Somdaka</b>
                  </small>
                </Timeline.Item>
              </Timeline>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default ClaimDetails;
