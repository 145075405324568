// utils/columnHelper.ts

import { formatToMoneyWithCurrency } from "./formatters"

export const getColumnDefinitions = (type: string) => {
	const baseColumns = [
		{
			title: "MembershipID",
			dataIndex: "MembershipID",
			key: "MembershipID",
			sorter: (a: any, b: any) => (a.MembershipID || "").localeCompare(b.MembershipID || ""),
		},
		{
			title: "LastName",
			dataIndex: "LastName",
			key: "LastName",
			sorter: (a: any, b: any) => (a.LastName || "").localeCompare(b.LastName || ""),
		},
		{
			title: "TransactionDate",
			dataIndex: "TransactionDate",
			key: "TransactionDate",
			sorter: (a: any, b: any) => new Date(a.TransactionDate || 0).getTime() - new Date(b.TransactionDate || 0).getTime(),
		},
		{
			title: "Credit",
			dataIndex: "Amount",
			key: "Amount",
			sorter: (a: any, b: any) => (a.Amount || 0) - (b.Amount || 0),
			render: (value: any) => formatToMoneyWithCurrency(value),
		},
		{
			title: "ReceiptNo",
			dataIndex: "ReceiptNo",
			key: "ReceiptNo",
			sorter: (a: any, b: any) => (a.ReceiptNo || "").localeCompare(b.ReceiptNo || ""),
		},
		{
			title: "CapturedBy",
			dataIndex: "CapturedBy",
			key: "CapturedBy",
			sorter: (a: any, b: any) => (a.CapturedBy || "").localeCompare(b.CapturedBy || ""),
		},
	]

	if (type === "easipolEasypayReceipts") {
		return [
			{
				title: "Policy",
				dataIndex: "Policy",
				key: "Policy",
				sorter: (a: any, b: any) => (a["Policy"] || "").localeCompare(b["Policy"] || ""),
			},
			{
				title: "Product",
				dataIndex: "Product",
				key: "Product",
				sorter: (a: any, b: any) => (a.Product || "").localeCompare(b.Product || ""),
			},
			{
				title: "Date",
				dataIndex: "TransactionDate",
				key: "TransactionDate",
				sorter: (a: any, b: any) => new Date(a.Date || 0).getTime() - new Date(b.Date || 0).getTime(),
			},
			{
				title: "Amount Collected",
				dataIndex: "Amount",
				key: "Amount",
				sorter: (a: any, b: any) => (a.Amount || 0) - (b.Amount || 0),
				render: (value: any) => formatToMoneyWithCurrency(value),
			},
		]
	}
	return baseColumns
}
