import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { CLIENT_SERVICE_URL } from '../../../app-constants';
import { IEasypayImportData } from '../../../interfaces/IEasypayImportData';
import { getAuthHeader } from '../../helpers';

// Define a type for the slice state
interface EasypayTransactionsState {
  importData: {
    count: number;
    imports: IEasypayImportData[];
  };
  transactions: any[];
  count: number;
  currentPage: number;
  totalPages: number;
  status: string;
  hasError: boolean;
  errorMessage: string;
}

// Define the initial state using that type
const initialState: EasypayTransactionsState = {
  importData: {
    count: 0,
    imports: [],
  },
  transactions: [],
  count: 0,
  currentPage: 0,
  totalPages: 0,
  status: 'idle',
  hasError: false,
  errorMessage: '',
};

export const getTransactions = createAsyncThunk('easypay/getTransactions', async (page: string = '0') => {
  try {
    const response = await axios.get(`${CLIENT_SERVICE_URL}transactions/easypay?p=${page}`, {
      headers: getAuthHeader(),
    });

    if (response.status === 200) {
      return response.data;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
});

export const importTransactions = createAsyncThunk('easypay/import', async (payload: any) => {
  try {
    const response = await axios.post(`${CLIENT_SERVICE_URL}transactions/easypay/import`, payload, {
      headers: getAuthHeader(),
    });

    if (response.status === 200) {
      return response.data;
    }
  } catch (error: any) {
    if (error.response.status === 400) {
      return {
        hasError: true,
        message: error.response.data.message,
      };
    }

    return false;
  }
});

export const getImportData = createAsyncThunk('easypay/getImportData', async () => {
  try {
    const response = await axios.get(`${CLIENT_SERVICE_URL}importdata/easypay`, {
      headers: getAuthHeader(),
    });

    if (response.status === 200) {
      const importData = {
        count: response.data.count,
        imports: response.data.imports,
      };

      return {
        importData,
        totalTransactions: response.data.transactionCount,
      };
    } else {
      console.log(response);
      return false;
    }
  } catch (error) {
    console.error(error);
    return false;
  }
});

export const searchTransactions = createAsyncThunk('easypay/search', async (text: string) => {
  try {
    const response = await axios.post(
      `${CLIENT_SERVICE_URL}transactions/easypay/search`,
      { searchText: text },
      {
        headers: getAuthHeader(),
      }
    );

    if (response.status === 200) {
      console.log(response.data);
      return response.data;
    } else {
      console.log(response);
      return false;
    }
  } catch (error) {
    console.error(error);
    return false;
  }
});

export const searchByAmount = createAsyncThunk('easypay/searchByAmount', async (payload: any) => {
  const { amount, filterType } = payload;

  try {
    const response = await axios.post(
      `${CLIENT_SERVICE_URL}transactions/easypay/filter`,
      {
        amount: amount,
        filterType: filterType,
      },
      {
        headers: getAuthHeader(),
      }
    );

    if (response.status === 200) {
      return response.data;
    } else {
      console.log(response);
      return false;
    }
  } catch (error) {
    console.error(error);
    return false;
  }
});

export const deleteTransactions = createAsyncThunk('easypay/deleteTransactions', async (_, thunkAPI: any) => {
  const state = thunkAPI.getState();

  const transactionState = state.transactions;

  const transactions = transactionState.transactions;

  const transactionIds: string[] = [];

  transactions.forEach((item: any) => {
    transactionIds.push(item._id);
  });

  try {
    const response = await axios.post(
      `${CLIENT_SERVICE_URL}transactions/easypay/deleteTransactions`,
      {
        ids: transactionIds,
      },
      {
        headers: getAuthHeader(),
      }
    );

    if (response.status === 200) {
      console.log(response.data);
      return response.data;
    } else {
      console.log(response);
      return false;
    }
  } catch (error) {
    console.error(error);
    return false;
  }
});

export const transactionsSlice = createSlice({
  name: 'easypay',
  initialState,
  reducers: {
    hydrateTransactions(state: any, action: any) {
      state.transactions = action.payload.transactions;
      state.currentPage = action.payload.currentPage;
      state.totalPages = action.payload.totalPages;
      if (action.payload.count) state.count = action.payload.count;
    },
    clearTransactions(state: any) {
      state.transactions = [];
      state.currentPage = 0;
      state.totalPages = 0;
    },
  },
  extraReducers: (builder: any) => {
    builder
      .addCase(deleteTransactions.pending, (state: any) => {
        state.status = 'Deleting selected transactions';
      })
      .addCase(deleteTransactions.rejected, (state: any) => {
        state.status = 'idle';
      })
      .addCase(deleteTransactions.fulfilled, (state: any, action: any) => {
        state.status = 'idle';
        state.transactions = [];
      })
      .addCase(importTransactions.pending, (state: any) => {
        state.status = 'Importing transactions';
        state.hasError = false;
        state.errorMessage = '';
      })
      .addCase(importTransactions.rejected, (state: any) => {
        state.status = 'idle';
      })
      .addCase(importTransactions.fulfilled, (state: any, action: any) => {
        state.status = 'idle';
        if (action.payload.hasError) {
          state.hasError = true;
          state.errorMessage = action.payload.message;
        }
      })
      .addCase(getImportData.pending, (state: any) => {
        state.status = 'Fetching EFT Import Data';
      })
      .addCase(getImportData.rejected, (state: any) => {
        state.status = 'idle';
      })
      .addCase(getImportData.fulfilled, (state: any, action: any) => {
        state.status = 'idle';
        state.importData = action.payload.importData;
        state.count = action.payload.totalTransactions;
      })
      .addCase(searchTransactions.pending, (state: any) => {
        state.status = 'Searching transactions by Easypay Number';
      })
      .addCase(searchTransactions.rejected, (state: any) => {
        state.status = 'idle';
      })
      .addCase(searchTransactions.fulfilled, (state: any, action: any) => {
        state.status = 'idle';

        let updatedItems = [...action.payload];
        updatedItems.map((item) => (item.key = item._id));

        state.transactions = updatedItems;
      })
      .addCase(searchByAmount.pending, (state: any) => {
        state.status = 'Searching transactions by amount';
      })
      .addCase(searchByAmount.rejected, (state: any) => {
        state.status = 'idle';
      })
      .addCase(searchByAmount.fulfilled, (state: any, action: any) => {
        state.status = 'idle';

        let updatedItems = [...action.payload];
        updatedItems.map((item) => (item.key = item._id));

        state.transactions = updatedItems;
      })
      .addCase(getTransactions.pending, (state: any) => {
        state.status = 'Fetching transactions';
      })
      .addCase(getTransactions.rejected, (state: any) => {
        state.status = 'idle';
      })
      .addCase(getTransactions.fulfilled, (state: any, action: any) => {
        state.status = 'idle';
        state.count = action.payload.count;

        let updatedItems = [...action.payload.transactions];
        updatedItems.map((item) => (item.key = item._id));

        state.transactions = updatedItems;
        state.currentPage = action.payload.currentPage;
        state.totalPages = action.payload.totalPages;
      });
  },
});

export const { hydrateTransactions, clearTransactions } = transactionsSlice.actions;

export default transactionsSlice.reducer;
