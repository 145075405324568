import { PageHeader } from 'antd';

const MortuaryRegister = () => {
  return (
    <>
      <PageHeader
        className='site-page-header'
        title='Mortuary Register'
        subTitle='This is where you will see a summary of all the bodies moving through the coldroom'
      />
    </>
  );
};

export default MortuaryRegister;
