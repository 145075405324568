import { Divider } from 'antd';
import { useAppSelector } from '../../redux/hook';
import './styles.scss';

export const ThinDivider = ({ label = '' }: any) => {
  const { theme } = useAppSelector((state) => state.preferences);

  return (
    <Divider className={`custom-divider thin-divider ${theme.toLowerCase()}`} plain>{label}</Divider>
  )
}
