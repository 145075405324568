import { DeleteOutlined, DownloadOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Input, PageHeader, Select, Space, Table, Tag, Tooltip, Upload } from 'antd';
import { RcFile } from 'antd/lib/upload';
import { useEffect, useState } from 'react';
import { BASE_URL } from '../app-constants';
import ImageLink from '../components/imageLink';
import { addDocument, deleteDocument, listDocuments } from '../redux/features/documents/documents.slice';
import { useAppDispatch, useAppSelector } from '../redux/hook';
import { formatUCTtoISO } from '../utils/formatters';

const Option = Select.Option;

const Documents = () => {
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.auth);
  const { referenceLists } = useAppSelector((state) => state.settings);
  const { documents } = useAppSelector((state) => state.documents);

  const [newDoc, setNewDoc] = useState({
    name: '',
    category: '',
    createdBy: {
      username: user.fullnames,
      userId: user._id,
    },
  });

  useEffect(() => {
    dispatch(listDocuments());
  }, [dispatch]);

  // const categories = [
  //   'Agreement',
  //   'Contract',
  //   'Meeting Minutes',
  //   'Report',
  //   'Company Policy',
  //   'Compliance and Regulation',
  //   'Form',
  //   'License',
  //   'Trademark',
  //   'Terms and Conditions',
  //   'Miscellaneous',
  // ];

  const clearForm = () => {
    setNewDoc({
      name: '',
      category: '',
      createdBy: {
        username: user.fullnames,
        userId: user._id,
      },
    });
  };

  const handleFileUpload = (file: RcFile) => {
    const payload = {
      data: {
        ...newDoc,
        size: `${file.size / 1000} KB`,
        fileSize: file.size,
      },
      file,
    };

    dispatch(addDocument(payload));
    clearForm();
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'Category',
      dataIndex: 'category',
    },
    {
      title: 'Type',
      render: (value: string, record: any, index: number) => {
        const extension = record.file.filename.split('.').pop();

        if (extension === 'mp3') return <Tag>Audio</Tag>;
        if (extension === 'pub') return <Tag color='green'>MS Publisher</Tag>;
        if (extension === 'ppt') return <Tag color='orange'>MS Powerpoint</Tag>;
        if (extension === 'xls' || extension === 'xlsx') return <Tag color='green'>MS Excel</Tag>;
        if (extension === 'doc' || extension === 'docx') return <Tag color='blue'>MS Word</Tag>;
        if (extension === 'pdf' || extension === 'tiff') return <Tag color='red'>PDF</Tag>;
        if (extension === 'jpg' || extension === 'jpeg' || extension === 'png') return <Tag color='yellow'>Image</Tag>;
        if (extension === 'mp4' || extension === '3gp' || extension === 'mkv') return <Tag color='purple'>Video</Tag>;
        if (extension === 'jar' || extension === '7z' || extension === 'zip') return <Tag>Archive</Tag>;
      },
    },
    {
      title: 'Size',
      dataIndex: 'fileSize',
      render: (value: any) => {
        const gigabytes = Number((value / 1000000000).toFixed(2));
        const megabytes = Number((value / 1000000).toFixed(2));
        const kilobytes = Number((value / 1000).toFixed(2));

        return (
          <span>{gigabytes < 1 ? (megabytes < 1 ? `${kilobytes} KB` : `${megabytes} MB`) : `${gigabytes} GB`}</span>
        );
      },
    },
    {
      title: 'Author',
      dataIndex: 'createdBy',
      render: (text: string, record: any, index: number) => <span>{record?.createdBy?.username}</span>,
    },
    {
      title: 'Date Uploaded',
      dataIndex: 'createdAt',
      render: (text: string, record: any, index: number) => <span>{formatUCTtoISO(text)}</span>,
    },
    {
      title: 'Actions',
      render: (text: string, record: any, index: number) => {
        return (
          <Space>
            {record?.file?.mimetype?.includes('image') && (
              <ImageLink url={`${BASE_URL}/documents/${record?.key}/${record?.file?.filename}`} />
            )}
            <Tooltip title='Download'>
              <Button
                size='small'
                type='link'
                download
                target={'_blank'}
                href={`${BASE_URL}/documents/${record?.key}/${record?.file?.filename}`}>
                <DownloadOutlined />
              </Button>
            </Tooltip>
            <Tooltip title='Delete'>
              <Button size='small' type='link' danger onClick={() => dispatch(deleteDocument(record.key))}>
                <DeleteOutlined />
              </Button>
            </Tooltip>
          </Space>
        );
      },
    },
  ];

  const categories = referenceLists.find((e) => e.name === 'Documents')?.options ?? [];

  return (
    <>
      <PageHeader
        className='site-page-header'
        title='Documents'
        extra={
          <Space>
            <Input
              value={newDoc.name}
              placeholder='Document Name'
              style={{ minWidth: '200px' }}
              onChange={(event) => setNewDoc({ ...newDoc, name: event.target.value })}
            />
            <Select
              value={newDoc.category}
              onChange={(value) => setNewDoc({ ...newDoc, category: value })}
              style={{ minWidth: '200px' }}>
              {categories.map((item, index) => (
                <Option key={item}>{item}</Option>
              ))}
            </Select>
            <Upload
              action='uploadUrl'
              name='file'
              showUploadList={false}
              beforeUpload={(file: RcFile) => {
                handleFileUpload(file);
                return false;
              }}>
              <Button
                type='primary'
                style={{ margin: '0' }}
                disabled={newDoc.name.length < 1 || newDoc.category.length < 1}>
                <PlusOutlined /> Add Document
              </Button>
            </Upload>
          </Space>
        }
      />
      <div className='documents-page page'>
        <div className='container'>
          <Table
            columns={columns}
            dataSource={documents}
            size='small'
            pagination={{
              position: ['bottomRight'],
              onChange: () => {},
              total: documents.length,
              pageSize: 20,
              showSizeChanger: false,
            }}
          />
        </div>
      </div>
    </>
  );
};

export default Documents;
