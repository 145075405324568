import { Col, PageHeader, Row, Space, Statistic, Table, Tag } from "antd"
import Papa from "papaparse"
import axios from "axios"
import { CLIENT_SERVICE_URL } from "../app-constants"
import { useAppDispatch, useAppSelector } from "../redux/hook"
import { useEffect } from "react"
import { getPolicies } from "../redux/features/policies/policies.slice"
import { formatUCTtoISO } from "../utils/formatters"
import { getAuthHeader } from "../redux/helpers"

const Policies = () => {
	const dispatch = useAppDispatch()
	const { policies, activePoliciesCount, inactivePoliciesCount, lapsedPoliciesCount, totalPoliciesCount } = useAppSelector((state) => state.policies)

	const onPolicyFileChange = (event: any) => {
		Papa.parse(event.target.files[0], {
			header: true,
			skipEmptyLines: true,
			complete: function (results: any) {
				processPolicyData(results.data)
			},
		})
	}

	const onEasypayFileChange = (event: any) => {
		Papa.parse(event.target.files[0], {
			header: true,
			skipEmptyLines: true,
			complete: function (results: any) {
				processEasypayData(results.data)
			},
		})
	}

	const processEasypayData = async (fileData: any) => {
		const batches = []

		const limit = 10000

		for (let i = 0; i < fileData.length; i += limit) {
			const batch = fileData.slice(i, i + limit)

			batches.push(batch)
		}

		await sendEasypayNumbers(batches)
	}

	const processPolicyData = async (fileData: any) => {
		const policies = fileData.filter((e: any) => e.membertype === "M")
		const dependents = fileData.filter((e: any) => e.membertype !== "M" && e.product !== "Repatriation" && e.product !== "Grave Cover")
		const extraCovers = fileData.filter((e: any) => e.product === "Repatriation" || e.product === "Grave Cover")

		const batches = []

		const limit = 10000

		for (let i = 0; i < dependents.length; i += limit) {
			const batch = dependents.slice(i, i + limit)

			const updatedBatch = await batch.map((dependent: any) => {
				let date = new Date(dependent.BirthDate).toUTCString()
				date = formatUCTtoISO(date).replace("/", "-").replace("/", "-")

				return {
					...dependent,
					BirthDate: date,
				}
			})

			batches.push(updatedBatch)
		}

		await sendPolicyData(policies)
		await sendExtraCovers(extraCovers)
		await sendPolicyDependents(batches)
	}

	const onReceiptFileChange = (event: any) => {
		Papa.parse(event.target.files[0], {
			header: true,
			skipEmptyLines: true,
			complete: function (results: any) {
				processReceiptData(results.data)
			},
		})
	}

	const processReceiptData = async (fileData: any) => {
		const batches = []

		const limit = 10000

		for (let i = 0; i < fileData.length; i += limit) {
			const batch = fileData.slice(i, i + limit)

			const updatedBatch = await batch.map((data: any) => {
				const receipt = {
					amount: data.Total,
					branch: data.BranchName,
					collectedBy: data.CollectedBy,
					datePaid: data.datepaid,
					debitMethod: data.DebitMethod,
					group: data.GroupName,
					inceptionDate: data.InceptionDate,
					manualReceiptNumber: data.ManualReceiptNumber,
					monthsPaidInAdvance: data.MonthsPaidInAdvance,
					paymentMethod: data.PaymentMethod,
					amountDue: data.PremiumDue,
					product: data.Product_Name,
					month: data.MonthNumber,
					receiptNumber: data.ReceiptNumber,
					year: data.YearNumber,
					remarks: data.Remarks,
					status: data.Satatus,
					fromDate: data.fromDate,
					policyNumber: data.policy_number,
					surname: data.surname,
				}

				return receipt
			})

			batches.push(updatedBatch)
		}

		await sendPolicyReceipts(batches)
	}

	const sendPolicyData = async (policies: any[]) => {
		await axios.post(`${CLIENT_SERVICE_URL}policies/importPolicies`, policies, {
			headers: getAuthHeader(),
		})
	}

	const sendExtraCovers = async (extraCovers: any[]) => {
		await axios.post(`${CLIENT_SERVICE_URL}policies/importExtraCovers`, extraCovers, {
			headers: getAuthHeader(),
		})
	}

	const sendEasypayNumbers = async (batches: any[]) => {
		for (let i = 0; i < batches.length; i++) {
			await axios.post(
				`${CLIENT_SERVICE_URL}policies/importEasypayNumbers`,
				{ batchId: `EN-BATCH-${i}`, batch: batches[i] },
				{
					headers: getAuthHeader(),
				}
			)
		}
	}

	const sendPolicyDependents = async (batches: any[]) => {
		for (let i = 0; i < batches.length; i++) {
			await axios.post(
				`${CLIENT_SERVICE_URL}policies/importDependents`,
				{ batchId: `PD-BATCH-${i}`, batch: batches[i] },
				{
					headers: getAuthHeader(),
				}
			)
		}
	}

	const sendPolicyReceipts = async (batches: any[]) => {
		for (let i = 0; i < batches.length; i++) {
			await axios.post(
				`${CLIENT_SERVICE_URL}policies/importReceipts`,
				{ batchId: `PR-BATCH-${i}`, batch: batches[i] },
				{
					headers: getAuthHeader(),
				}
			)
		}
	}

	useEffect(() => {
		if (dispatch) dispatch(getPolicies("0"))

		return () => {}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const columns = [
		{
			title: "Policy Number",
			dataIndex: "policyNumber",
		},
		{
			title: "Product",
			dataIndex: "productName",
		},
		{
			title: "Group",
			dataIndex: "groupName",
			render: (text: string) => <span>{text?.toUpperCase()}</span>,
		},
		{
			title: "Status",
			dataIndex: "currentStatus",
			render: (text: string) => (
				<Tag color={text?.toLowerCase() === "active" ? "green" : text?.toLowerCase() === "inactive" ? "orange" : "red"}>{text}</Tag>
			),
		},
		{
			title: "Policy Holder",
			dataIndex: "fullname",
		},
		{
			title: "Identification Number",
			render: (text: string, record: any, index: number) => <span>{record.idNumber.length > 0 ? record.idNumber : record.passportNumber}</span>,
		},
		{
			title: "Mobile Number",
			dataIndex: "cellNumber",
		},
		{
			title: "Physical Address",
			dataIndex: "physicalAddress",
		},
	]

	const handlePageChange = (page: number, pageSize: number) => {
		dispatch(getPolicies(page.toString()))
	}

	return (
		<>
			<PageHeader className='site-page-header' title='Policies'>
				<Row>
					<Col span={12}>
						<Space size={72}>
							<Statistic title='Total Policies' value={totalPoliciesCount} />
							<Statistic title='Active Policies' value={activePoliciesCount} />
							<Statistic title='Inactive Policies' value={inactivePoliciesCount} />
							<Statistic title='Lapsed Policies' value={lapsedPoliciesCount} />
						</Space>
					</Col>
					<Col span={4}>
						<h3>Policies (CSV)</h3>
						<input type='file' name='file' accept='.csv' onChange={onPolicyFileChange} style={{ display: "block", margin: "10px" }} />
					</Col>
					<Col span={4}>
						<h3>Easypay Numbers (CSV)</h3>
						<input type='file' name='file' accept='.csv' onChange={onEasypayFileChange} style={{ display: "block", margin: "10px" }} />
					</Col>
					<Col span={4}>
						<h3>Receipts (CSV)</h3>
						<input type='file' name='file' accept='.csv' onChange={onReceiptFileChange} style={{ display: "block", margin: "10px" }} />
					</Col>
				</Row>
			</PageHeader>
			<div className='policies-page page'>
				<div className='container'>
					<Table
						columns={columns}
						dataSource={policies}
						size='small'
						pagination={{
							position: ["topRight", "bottomRight"],
							onChange: handlePageChange,
							total: totalPoliciesCount,
							pageSize: 20,
							showSizeChanger: false,
						}}
					/>
				</div>
			</div>
		</>
	)
}

export default Policies
