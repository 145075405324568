import { Button, Col, PageHeader, Row, Space, Statistic, Table, Tag } from 'antd';
import { useAppDispatch, useAppSelector } from '../redux/hook';
import { useEffect } from 'react';
import { getClaims, selectClaim } from '../redux/features/claims/claims.slice';
import { PlusOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

const Claims = () => {
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const {
    claims,
    totalClaimCount,
    submittedCount,
    underReviewCount,
    approvedCount,
    rejectedCount,
    processingCount,
    paidCount,
  } = useAppSelector((state) => state.claims);

  useEffect(() => {
    if (dispatch) dispatch(getClaims('0'));

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleReviewClick = (claim: any) => {
    dispatch(selectClaim(claim));
    navigate(`/claims/${claim._id}`);
  };

  const columns = [
    {
      title: 'Policy Number',
      dataIndex: 'policyNumber',
    },
    {
      title: 'Policy Holder',
      dataIndex: 'policyHolder',
    },
    // {
    //   title: 'Cover Amount',
    //   dataIndex: 'coverAmount',
    // },
    {
      title: 'Claimant',
      dataIndex: 'claimant',
    },
    {
      title: 'Claimant Contact',
      dataIndex: 'claimantContact',
    },
    {
      title: 'Claim Amount',
      dataIndex: 'claimAmount',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (text: string) => {
        return <Tag>{text}</Tag>;
      },
    },
    {
      title: 'Actions',
      render: (text: string, record: any, index: number) => {
        return (
          <Space>
            {record.status === 'Submitted' && (
              <Button onClick={() => handleReviewClick(record)} type='primary' size='small'>
                Review
              </Button>
            )}
          </Space>
        );
      },
    },
  ];

  const handlePageChange = (page: number, pageSize: number) => {
    // dispatch(getClaims(page.toString()));
  };

  return (
    <>
      <PageHeader
        className='site-page-header'
        title='Claims'
        extra={[
          <Button key='1' type='primary' onClick={() => navigate('/claims/create')}>
            <PlusOutlined /> New Claim
          </Button>,
        ]}>
        <Row>
          <Col span={20}>
            <Space size={72}>
              <Statistic title='Submitted' value={submittedCount} />
              <Statistic title='Under Review' value={underReviewCount} />
              <Statistic title='Approved' value={approvedCount} />
              <Statistic title='Rejected' value={rejectedCount} />
              <Statistic title='Payment Processing' value={processingCount} />
              <Statistic title='Paid' value={paidCount} />
            </Space>
          </Col>
        </Row>
      </PageHeader>
      <div className='claims-page page'>
        <div className='container'>
          <Table
            columns={columns}
            dataSource={claims}
            size='small'
            pagination={{
              position: ['topRight', 'bottomRight'],
              onChange: handlePageChange,
              total: totalClaimCount,
              pageSize: 20,
              showSizeChanger: false,
            }}
          />
        </div>
      </div>
    </>
  );
};

export default Claims;
