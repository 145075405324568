import { PageHeader, Space, Table, Tabs, Tag } from 'antd';
import ReferenceListBuilder from '../components/builder_referenceList';
import IReferenceList from '../models/reference-list.model';
import { useAppSelector } from '../redux/hook';

const TabPane = Tabs.TabPane;

const Settings = () => {

  const { referenceLists } = useAppSelector((state) => state.settings);

  const referenceListColumns = [
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'Options',
      dataIndex: 'options',
      render: (text: string, record: IReferenceList, index: number) => (
        <>
          {record.options.map((item: string) => (
            <Tag>{item}</Tag>
          ))}
        </>
      ),
    },
  ];

  const onTabChange = (tabKey: string) => {
    console.log(tabKey);
  };

  return (
    <>
      <PageHeader className='site-page-header' title='Settings' />
      <div className='settings-page page'>
        <div className='card-container'>
          <Tabs defaultActiveKey='1' onChange={onTabChange} type='card'>
            <TabPane tab='Reference Lists' key='1'>
              <div className='table-controls' style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div className='advanced-filter'></div>
                <Space>
                  <ReferenceListBuilder />
                </Space>
              </div>
              <Table size='small' columns={referenceListColumns} dataSource={referenceLists} />
            </TabPane>
          </Tabs>
        </div>
      </div>
    </>
  );
};

export default Settings;
