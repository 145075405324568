import { PageHeader, Popover, Tag, Card, Divider, Badge, Button } from "antd"
import React, { useEffect, useState } from "react"
import { getReferenceLists } from "../redux/features/settings/settings.slice"
import { getFunerals, setSelectedFuneral } from "../redux/features/funerals/funerals.slice"
import { useAppDispatch, useAppSelector } from "../redux/hook"
import FullCalendar from "@fullcalendar/react"
import dayGridPlugin from "@fullcalendar/daygrid" // a plugin!
import timeGridPlugin from "@fullcalendar/timegrid" // a plugin!
import interactionPlugin from "@fullcalendar/interaction" // needed for dayClick
import churchImage from "../assets/images/church.png"
import cemeteryImage from "../assets/images/cemetery.png"
import familyHouseImage from "../assets/images/home.png"

import {
	ClockCircleOutlined,
	EditOutlined,
	PlusOutlined,
	WhatsAppOutlined,
} from "@ant-design/icons"
import {} from "antd"
import { useNavigate } from "react-router-dom"

const Funerals = () => {
	const dispatch = useAppDispatch()
  const { funerals } = useAppSelector((state) => state.funerals)

	const navigate = useNavigate()

	useEffect(() => {
		dispatch(getReferenceLists())

		// getFunerals()
		dispatch(getFunerals())

		return () => {}
	}, [])

	const handleDateClick = (event: any) => {
		console.log("🪵 ~ handleDateClick ~ event => ", event)
	}

	// a custom render function
	function renderEventContent(eventInfo: any) {
		const { event, timeText: time } = eventInfo
		const { extendedProps, id } = event
		const { address, coffin, informant_whatsapp_number, church, church_address, is_claim, grave_marker, deceased_name, deceased_surname, cemetery } = extendedProps

		// console.log("🪵 ~ renderEventContent ~ Event ~ ", eventInfo.event)

		const whatsAppLink = `https://wa.me/${informant_whatsapp_number}`

		const actions = [
			<div key='update'
				onClick={() => {
					dispatch(setSelectedFuneral(id))
					navigate({
						pathname: `/funerals/edit`,
						search: `?id=${id}`,
					})
				}}>
				<EditOutlined />
				{" UPDATE"}
			</div>,
			// <EllipsisOutlined key='ellipsis' />,
		]

		if (informant_whatsapp_number) {
			actions.push(
				<a key='sendWhatsApp' target='_blank' rel='noreferrer' href={whatsAppLink}>
					<WhatsAppOutlined key='whatsapp' />
					{" WHATSAPP"}
				</a>
			)
		}

		const content = (
			<Card
				size='small'
				style={{ width: 400 }}
				bodyStyle={{ padding: "1rem 1.5rem" }}
				// cover={<img alt={coffin} src={coffin_image_url} />}
				actions={actions}>
				<div>
					<h3>{deceased_name} {deceased_surname}</h3>
					<Divider style={{ margin: "0 0 1rem 0" }} />
					<p style={{ marginBottom: ".5rem" }}>
						<img height={24} alt='family house' src={familyHouseImage} style={{ marginRight: "5px" }} /> {address}
					</p>
					{church && (
						<p style={{ marginBottom: ".5rem" }}>
							<img height={24} alt='church' src={churchImage} style={{ marginRight: "5px" }} /> {church_address}
						</p>
					)}
					<p style={{ marginBottom: ".5rem" }}>
						<img height={24} alt='cemetery' src={cemeteryImage} style={{ marginRight: "5px" }} /> {cemetery} Cemetery
					</p>
					<p style={{ marginBottom: ".5rem" }}>
						<ClockCircleOutlined /> {time}
					</p>
					<Divider style={{ margin: ".5rem 0" }} orientation='center'>
						<small>Notes</small>
					</Divider>
					<p style={{ marginBottom: "0", fontWeight: "500" }}>{coffin}</p>
					{grave_marker && <p style={{ marginBottom: "0", fontWeight: "500" }}>Has Grave Marker</p>}
				</div>
			</Card>
		)

		return (
			<Popover
				content={
					is_claim ? (
						<Badge.Ribbon text='Claim' color='red'>
							{content}
						</Badge.Ribbon>
					) : (
						<Badge.Ribbon text='Cash' color='blue'>
							{content}
						</Badge.Ribbon>
					)
				}
				key={id}>
				<Tag color={is_claim ? "red" : "blue"}>
					<b>
						{eventInfo.timeText} {deceased_surname} Funeral
					</b>
				</Tag>
			</Popover>
		)
	}

	const headerToolbarOptions = {
		start: "prev,today,next",
		center: "title",
		end: "dayGridMonth,timeGridWeek,timeGridDay",
	}

	return (
		<>
			<PageHeader
				className='site-page-header'
				title={`Funeral Calendar`}
				// subTitle='This is where you will see all the current funerals'
				extra={[
					<Button
						onClick={() => {
							navigate("/funerals/create")
						}}
						icon={<PlusOutlined />}>
						Add Funeral
					</Button>,
				]}
			/>
			<div className='funerals-page page'>
				<div style={{ padding: "2rem" }}>
					<FullCalendar
						plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
						initialView='dayGridMonth'
						headerToolbar={headerToolbarOptions}
						dateClick={handleDateClick}
						eventContent={renderEventContent}
						events={funerals}
						eventTimeFormat={{ hour: "2-digit", minute: "2-digit", hour12: false }}
						height={"85vh"}
						firstDay={1}
					/>
				</div>
			</div>
		</>
	)
}

export default Funerals
