import { CloseCircleFilled } from '@ant-design/icons';
import { DatePicker, notification, Space } from 'antd';
import { useState, useRef } from 'react';
import * as XLSX from 'xlsx';
import { importExcelTransactions } from '../../redux/features/transactions/eft.slice';
import { useAppDispatch } from '../../redux/hook';

export const ExcelImportTool = () => {
  const fileRef = useRef() as React.MutableRefObject<HTMLInputElement>;

  const [fileName, setFileName] = useState(null);
  const [statementMonth, setStatementMonth] = useState<any>(undefined);
  
  const dispatch = useAppDispatch();

  const acceptableFileTypes = ['xlsx', 'xls'];

  const isAcceptableFile = (name: string) => {
    let extension = name.split('.').pop()?.toLowerCase();

    if (extension) return acceptableFileTypes.includes(extension);

    return false;
  };

  const parseExcelData = (data: any) => {
    const workbook = XLSX.read(data);

    let _sheetData: any[] = [];

    for (let index = 0; index < workbook.SheetNames.length; index++) {
      let sheetName = workbook.SheetNames[index];

      const worksheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet);

      if (jsonData.length > 0) {
        _sheetData = [..._sheetData, ...jsonData];
      }
    }

    let payload = { statementMonth: statementMonth, transactions: _sheetData, uuid: statementMonth };

    dispatch(importExcelTransactions(payload));
  };

  const handleFile = async (event: any) => {
    const _file = event.target.files[0];

    if (!_file) return;

    if (!isAcceptableFile(_file.name)) {
      notification.error({
        message: 'Invalid File Type',
        description: 'Please upload xlsx or xls files only',
      });
      return;
    }

    setFileName(_file.name);

    const data = await _file.arrayBuffer();

    parseExcelData(data);
  };

  const handleRemoveFile = () => {
    setStatementMonth(undefined);
    setFileName(null);
    fileRef.current.value = '';
  };

  const onMonthChange = (_date: any, dateString: string) => {
    if (dateString.length === 0) {
      setStatementMonth(undefined);
    } else {
      setStatementMonth(dateString);
    }
  };

  return (
    <div>
      {fileName && (
        <label style={{ color: '#999', textTransform: 'uppercase', letterSpacing: '0.15rem' }}>
          File Name: <span style={{}}>{fileName}</span>{' '}
          <CloseCircleFilled onClick={handleRemoveFile} style={{ color: '#ff4d4f', cursor: 'pointer' }} />
        </label>
      )}
      {!fileName && (
        <label style={{ color: '#999', textTransform: 'uppercase', letterSpacing: '0.15rem' }}>Import Excel File</label>
      )}
      <Space style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <DatePicker placeholder='Statement Month' onChange={onMonthChange} picker='month' />
        <input
          type='file'
          name='file'
          multiple={false}
          accept='xlsx, xls'
          ref={fileRef}
          disabled={statementMonth === undefined}
          onChange={handleFile}
          style={{ display: 'block', margin: '10px 0' }}
        />
      </Space>
    </div>
  );
};
