import { PageHeader } from 'antd';
import { useEffect } from 'react';
import { getReferenceLists } from '../redux/features/settings/settings.slice';
import { useAppDispatch } from '../redux/hook';

const Dashboard = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getReferenceLists());

    return () => {};
  }, [dispatch]);

  return (
    <>
      <PageHeader
        className='site-page-header'
        title='Dashboard'
        subTitle='This is where you will see a summary of all the metrics on the system'
      />
      <div className='dashboard-page page'></div>
    </>
  );
};

export default Dashboard;
