import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { CLIENT_SERVICE_URL } from '../../../app-constants';
import { IGrave } from '../../../models/grave.model';
import { sortList } from '../../../utils/helpers';
import { getAuthHeader } from '../../helpers';

// Define a type for the slice state
interface GraveState {
  graves: any[];
  selectedGrave: any;
  count: number;
  currentPage: number;
  totalPages: number;
  status: string;
}

// Define the initial state using that type
const initialState: GraveState = {
  graves: [],
  selectedGrave: null,
  count: 0,
  currentPage: 0,
  totalPages: 0,
  status: 'idle',
};

export const addGrave = createAsyncThunk('graves/add', async (grave: IGrave) => {
  try {
    const response = await axios.post(`${CLIENT_SERVICE_URL}graves`, grave, {
      headers: getAuthHeader(),
    });

    if (response.status === 200) {
      return response.data;
    } else {
      console.log(response);
      return false;
    }
  } catch (error) {
    console.error(error);
    return false;
  }
});

export const updateGrave = createAsyncThunk('graves/update', async (grave: IGrave) => {
  try {
    const response = await axios.put(`${CLIENT_SERVICE_URL}graves`, grave, {
      headers: getAuthHeader(),
    });

    if (response.status === 200) {
      return response.data;
    } else {
      console.log(response);
      return false;
    }
  } catch (error) {
    console.error(error);
    return false;
  }
});

export const getGraves = createAsyncThunk('graves/list', async (page: string = '0') => {
  try {
    const response = await axios.get(`${CLIENT_SERVICE_URL}graves?p=${page}`, {
      headers: getAuthHeader(),
    });

    if (response.status === 200) {
      return response.data;
    } else {
      console.log(response);
      return false;
    }
  } catch (error) {
    console.error(error);
    return false;
  }
});

export const searchGraves = createAsyncThunk('graves/search', async (text: string) => {
  try {
    const response = await axios.post(
      `${CLIENT_SERVICE_URL}graves/search`,
      { searchText: text },
      {
        headers: getAuthHeader(),
      }
    );

    if (response.status === 200) {
      return response.data;
    } else {
      console.log(response);
      return false;
    }
  } catch (error) {
    console.error(error);
    return false;
  }
});

export const deleteGrave = createAsyncThunk('graves/remove', async (id: string) => {
  try {
    const response = await axios.delete(`${CLIENT_SERVICE_URL}graves/${id}`, {
      headers: getAuthHeader(),
    });

    if (response.status === 200) {
      console.log(response);
      return id;
    } else {
      console.log(response);
      return false;
    }
  } catch (error) {
    console.error(error);
    return false;
  }
});

export const gravesSlice = createSlice({
  name: 'graves',
  initialState,
  reducers: {
    hydrateGraves(state: any, action: any) {
      state.graves = action.payload.graves;
      state.currentPage = action.payload.currentPage;
      state.totalPages = action.payload.totalPages;
    },
    setSelectedGrave(state: any, action: any) {
      state.selectedGrave = action.payload;
    },
  },
  extraReducers: (builder: any) => {
    builder
      .addCase(addGrave.pending, (state: any) => {
        state.status = 'Adding new grave';
      })
      .addCase(addGrave.rejected, (state: any) => {
        state.status = 'idle';
      })
      .addCase(addGrave.fulfilled, (state: any, action: any) => {
        state.status = action.payload.message;

        let updatedItems = [...state.graves, action.payload.doc];
        state.graves = updatedItems;
      })
      .addCase(updateGrave.pending, (state: any) => {
        state.status = 'Updating grave';
      })
      .addCase(updateGrave.rejected, (state: any) => {
        state.status = 'idle';
      })
      .addCase(updateGrave.fulfilled, (state: any, action: any) => {
        state.status = action.payload.message;
        let updatedItems = [...state.graves];

        let selectedGrave = state.selectedGrave;
        const index = updatedItems.findIndex(e => e.id === selectedGrave.id)

        if (index !== -1) {
          updatedItems[index] = { ...selectedGrave }
        }

        state.graves = updatedItems;
      })
      .addCase(searchGraves.pending, (state: any) => {
        state.status = 'Searching graves';
      })
      .addCase(searchGraves.rejected, (state: any) => {
        state.status = 'idle';
      })
      .addCase(searchGraves.fulfilled, (state: any, action: any) => {
        state.status = 'idle';

        let updatedItems = [...action.payload];
        updatedItems.map((item) => (item.key = item._id));

        state.graves = updatedItems;
      })
      .addCase(deleteGrave.pending, (state: any) => {
        state.status = 'pending';
      })
      .addCase(deleteGrave.rejected, (state: any, payload: any) => {
        state.status = 'failed';
      })
      .addCase(deleteGrave.fulfilled, (state: any, action: any) => {
        state.status = 'succeeded';

        let updatedList = [...state.graves];

        const index = updatedList.findIndex(e => e._id === action.payload);

        updatedList.map((item) => (item.key = item._id));
        updatedList.splice(index, 1);

        let sortedList = sortList(updatedList);
        state.graves = sortedList;
      })
      .addCase(getGraves.pending, (state: any) => {
        state.status = 'Fetching graves';
      })
      .addCase(getGraves.rejected, (state: any) => {
        state.status = 'idle';
      })
      .addCase(getGraves.fulfilled, (state: any, action: any) => {
        state.status = 'idle';

        let updatedItems = [...action.payload];
        updatedItems.map((item) => (item.key = item._id));

        state.graves = updatedItems;
      });
  },
});

export const { hydrateGraves, setSelectedGrave } = gravesSlice.actions;

export default gravesSlice.reducer;
