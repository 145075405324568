import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { CLIENT_SERVICE_URL } from '../../../app-constants';
import { getAuthHeader } from '../../helpers';

// Define a type for the slice state
interface PoliciesState {
  policies: any[];
  activePoliciesCount: number;
  inactivePoliciesCount: number;
  lapsedPoliciesCount: number;
  totalPoliciesCount: number;
  currentPage: number;
  totalPages: number;
}

// Define the initial state using that type
const initialState: PoliciesState = {
  policies: [],
  activePoliciesCount: 0,
  inactivePoliciesCount: 0,
  lapsedPoliciesCount: 0,
  totalPoliciesCount: 0,
  currentPage: 0,
  totalPages: 0,
};

export const getPolicies = createAsyncThunk('policies/getPolicies', async (page: string = '0') => {
  try {
    const response = await axios.get(`${CLIENT_SERVICE_URL}policies?p=${page}`, {
      headers: getAuthHeader(),
    });

    if (response.status === 200) {
      console.log(response.data);
      return response.data;
    } else {
      console.log(response);
      return false;
    }
  } catch (error) {
    console.error(error);
    return false;
  }
});

export const policiesSlice = createSlice({
  name: 'policies',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    hydratePolicies(state: any, action: any) {
      state.theme = action.payload.theme;
    },
  },
  extraReducers: (builder: any) => {
    builder
      .addCase(getPolicies.pending, (state: any) => {
        state.status = 'Fetching policies';
      })
      .addCase(getPolicies.rejected, (state: any, payload: any) => {
        state.status = 'idle';
      })
      .addCase(getPolicies.fulfilled, (state: any, action: any) => {
        state.status = 'idle';
        state.activePoliciesCount = action.payload.activePoliciesCount;
        state.inactivePoliciesCount = action.payload.inactivePoliciesCount;
        state.lapsedPoliciesCount = action.payload.lapsedPoliciesCount;
        state.totalPoliciesCount = action.payload.totalPoliciesCount;

        let updatedItems = [...action.payload.policies];
        updatedItems.map((item) => (item.key = item._id));

        state.policies = updatedItems;
        state.currentPage = action.payload.currentPage;
        state.totalPages = action.payload.totalPages;
      });
  },
});

export const { hydratePolicies } = policiesSlice.actions;

export default policiesSlice.reducer;
