import { CheckCircleTwoTone, CloseCircleTwoTone } from '@ant-design/icons';
import { Alert, PageHeader, Table } from 'antd';
import { useEffect } from 'react';
import { fetchUsers } from '../redux/features/user-manager/user-manager-slice';
import { useAppDispatch, useAppSelector } from '../redux/hook';

const UsersPage = () => {
  const dispatch = useAppDispatch();

  const { users } = useAppSelector((state) => state.users);

  useEffect(() => {
    dispatch(fetchUsers());

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = [
    {
      title: 'Username',
      render: (_text: string, record: any, _index: number) => (
        <span>
          {record.activated ? (
            <CheckCircleTwoTone twoToneColor='#52c41a' style={{ marginRight: '.75rem' }} />
          ) : (
            <CloseCircleTwoTone twoToneColor='#e93e3c' style={{ marginRight: '.75rem' }} />
          )}{' '}
          {record.name} {record.surname}
        </span>
      ),
    },
    {
      title: 'Role',
      render: (_: string, record: any, index: number) => <span>{record.role.name}</span>,
      key: 'role',
    },
    {
      title: 'Site',
      dataIndex: 'default_site',
      key: 'site',
    },
    {
      title: 'Email Address',
      dataIndex: 'email_address',
      key: 'emailAddress',
    },
    {
      title: 'Phone Number',
      dataIndex: 'contact_number',
      key: 'phoneNumber',
      render: (text: string) => (
        <span>{text[0] === '+' ? text : text.substring(0, 0) + '+27' + text.substring(1)}</span>
      ),
    },
  ];

  return (
    <>
      <div className='users-page page'>
        <PageHeader title='System Users' />
        <Alert
          message='If you require additional users added to the system, please contact your Client Administrator'
          type='info'
          banner
          showIcon
        />
        <br />
        <Table dataSource={users} columns={columns} />
      </div>
    </>
  );
};

export default UsersPage;
