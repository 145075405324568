import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { CLIENT_SERVICE_URL } from '../../../app-constants';
import { IEftImportData } from '../../../interfaces/IEftImportData';
import { getAuthHeader } from '../../helpers';

// Define a type for the slice state
interface EftTransactionsState {
  importData: {
    count: number;
    imports: IEftImportData[];
  };
  transactions: any[];
  count: number;
  currentPage: number;
  totalPages: number;
  status: string;
}

// Define the initial state using that type
const initialState: EftTransactionsState = {
  importData: {
    count: 0,
    imports: [],
  },
  transactions: [],
  count: 0,
  currentPage: 0,
  totalPages: 0,
  status: 'idle',
};

export const reverseImport = createAsyncThunk('eft/reverseImport', async (payload: IEftImportData) => {
  try {
    const response = await axios.post(`${CLIENT_SERVICE_URL}transactions/eft/importData/reverse`, payload, {
      headers: getAuthHeader(),
    });

    if (response.status === 200) {
      return response.data;
    } else {
      console.log(response);
      return false;
    }
  } catch (error) {
    console.error(error);
    return false;
  }
});

export const getImportData = createAsyncThunk('eft/getImportData', async () => {
  try {
    const response = await axios.get(`${CLIENT_SERVICE_URL}transactions/eft/importData`, {
      headers: getAuthHeader(),
    });

    if (response.status === 200) {
      const importData = {
        count: response.data.count,
        imports: response.data.imports,
      };

      return {
        importData,
        totalTransactions: response.data.transactionCount,
      };
    } else {
      console.log(response);
      return false;
    }
  } catch (error) {
    console.error(error);
    return false;
  }
});

export const getTransactions = createAsyncThunk('eft/getTransactions', async (page: string = '0') => {
  try {
    const response = await axios.get(`${CLIENT_SERVICE_URL}transactions/eft?p=${page}`, {
      headers: getAuthHeader(),
    });

    if (response.status === 200) {
      return response.data;
    } else {
      console.log(response);
      return false;
    }
  } catch (error) {
    console.error(error);
    return false;
  }
});

export const searchTransactions = createAsyncThunk('eft/search', async (text: string) => {
  try {
    const response = await axios.post(
      `${CLIENT_SERVICE_URL}transactions/eft/search`,
      { searchText: text },
      {
        headers: getAuthHeader(),
      }
    );

    console.log(response);

    if (response.status === 200) {
      console.log(response.data);
      return response.data;
    } else {
      console.log(response);
      return false;
    }
  } catch (error) {
    console.error(error);
    return false;
  }
});

export const searchByAmount = createAsyncThunk('eft/searchByAmount', async (payload: any) => {
  const { amount, filterType } = payload;

  try {
    const response = await axios.post(
      `${CLIENT_SERVICE_URL}transactions/eft/filter`,
      {
        amount: amount,
        filterType: filterType,
      },
      {
        headers: getAuthHeader(),
      }
    );

    if (response.status === 200) {
      console.log(response.data);
      return response.data;
    } else {
      console.log(response);
      return false;
    }
  } catch (error) {
    console.error(error);
    return false;
  }
});

export const importExcelTransactions = createAsyncThunk('eft/import-from-excel', async (payload: any) => {
  try {
    const response = await axios.post(`${CLIENT_SERVICE_URL}transactions/eft/importxls`, payload, {
      headers: getAuthHeader(),
    });

    if (response.status === 200) {
      return response.data;
    } else {
      console.log(response);
      return false;
    }
  } catch (error) {
    console.error(error);
    return false;
  }
});

export const deleteTransactions = createAsyncThunk('eft/deleteTransactions', async (_, thunkAPI: any) => {
  const state = thunkAPI.getState();

  const transactionState = state.transactions;

  const transactions = transactionState.transactions;

  const transactionIds: string[] = [];

  transactions.forEach((item: any) => {
    transactionIds.push(item._id);
  });

  try {
    const response = await axios.post(
      `${CLIENT_SERVICE_URL}transactions/eft/deleteTransactions`,
      {
        ids: transactionIds,
      },
      {
        headers: getAuthHeader(),
      }
    );

    if (response.status === 200) {
      console.log(response.data);
      return response.data;
    } else {
      console.log(response);
      return false;
    }
  } catch (error) {
    console.error(error);
    return false;
  }
});

export const transactionsSlice = createSlice({
  name: 'eft',
  initialState,
  reducers: {
    hydrateTransactions(state: any, action: any) {
      state.transactions = action.payload.transactions;
      state.currentPage = action.payload.currentPage;
      state.totalPages = action.payload.totalPages;
      if (action.payload.count) state.count = action.payload.count;
    },
    clearTransactions(state: any) {
      state.transactions = [];
      state.currentPage = 0;
      state.totalPages = 0;
      state.status = 'idle';
    },
  },
  extraReducers: (builder: any) => {
    builder
      .addCase(reverseImport.pending, (state: any) => {
        state.status = 'Reversing selected import';
      })
      .addCase(reverseImport.rejected, (state: any) => {
        state.status = 'idle';
      })
      .addCase(reverseImport.fulfilled, (state: any, action: any) => {
        state.status = 'idle';
        state.transactions = [];
      })
      .addCase(deleteTransactions.pending, (state: any) => {
        state.status = 'Deleting selected transactions';
      })
      .addCase(deleteTransactions.rejected, (state: any) => {
        state.status = 'idle';
      })
      .addCase(deleteTransactions.fulfilled, (state: any, action: any) => {
        state.status = 'idle';
        state.transactions = [];
      })
      .addCase(searchTransactions.pending, (state: any) => {
        state.status = 'Searching transactions by reference';
      })
      .addCase(searchTransactions.rejected, (state: any) => {
        state.status = 'idle';
      })
      .addCase(searchTransactions.fulfilled, (state: any, action: any) => {
        state.status = 'idle';

        let updatedItems = [...action.payload];
        updatedItems.map((item) => (item.key = item._id));

        state.transactions = updatedItems;
      })
      .addCase(searchByAmount.pending, (state: any) => {
        state.status = 'Searching transactions by amount';
      })
      .addCase(searchByAmount.rejected, (state: any) => {
        state.status = 'idle';
      })
      .addCase(searchByAmount.fulfilled, (state: any, action: any) => {
        state.status = 'idle';
        state.transactions = action.payload;
      })
      .addCase(importExcelTransactions.pending, (state: any) => {
        state.status = 'Importing transactions from Excel File';
      })
      .addCase(importExcelTransactions.rejected, (state: any) => {
        state.status = 'idle';
      })
      .addCase(importExcelTransactions.fulfilled, (state: any, action: any) => {
        state.status = 'idle';
        // state.transactions = action.payload;
      })
      .addCase(getImportData.pending, (state: any) => {
        state.status = 'Fetching EFT Import Data';
      })
      .addCase(getImportData.rejected, (state: any) => {
        state.status = 'idle';
      })
      .addCase(getImportData.fulfilled, (state: any, action: any) => {
        state.status = 'idle';
        state.importData = action.payload.importData;
        state.count = action.payload.totalTransactions;
      })
      .addCase(getTransactions.pending, (state: any) => {
        state.status = 'Fetching transactions';
      })
      .addCase(getTransactions.rejected, (state: any) => {
        state.status = 'idle';
      })
      .addCase(getTransactions.fulfilled, (state: any, action: any) => {
        state.status = 'idle';
        state.count = action.payload.count;

        let updatedItems = [...action.payload.transactions];
        updatedItems.map((item) => (item.key = item._id));

        state.transactions = updatedItems;
        state.currentPage = action.payload.currentPage;
        state.totalPages = action.payload.totalPages;
      });
  },
});

export const { hydrateTransactions, clearTransactions } = transactionsSlice.actions;

export default transactionsSlice.reducer;
