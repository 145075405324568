import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { CLIENT_SERVICE_URL } from '../../../app-constants';
import { getAuthHeader } from '../../helpers';

// Define a type for the slice state
interface SignupRequestState {
  signupRequests: any[];
  selectedSignupRequest?: any;

  stats: any;
}

// Define the initial state using that type
const initialState: SignupRequestState = {
  signupRequests: [],

  stats: {
    submittedCount: 0,
    draftsCount: 0,
    reviewedCount: 0,
    acceptedCount: 0,
    rejectedCount: 0,
    archivedCount: 0,
  },
};

export const listSignupRequests = createAsyncThunk('signup-requests/list', async () => {
  try {
    const response = await axios.get(`${CLIENT_SERVICE_URL}signups`, {
      headers: getAuthHeader(),
    });

    if (response.status === 200) {
      return response.data;
    } else {
      console.log(response);
      return false;
    }
  } catch (error) {
    console.error(error);
    return false;
  }
});

export const getSignupRequest = createAsyncThunk('signup-requests/select', async (id: string) => {
  try {
    const response = await axios.get(`${CLIENT_SERVICE_URL}signups/${id}`, {
      headers: getAuthHeader(),
    });

    if (response.status === 200) {
      return response.data;
    } else {
      console.log(response);
      return false;
    }
  } catch (error) {
    console.error(error);
    return false;
  }
});

export const deleteSignupRequest = createAsyncThunk('signup-requests/remove', async (id: string) => {
  try {
    const response = await axios.delete(`${CLIENT_SERVICE_URL}signups/${id}`, {
      headers: getAuthHeader(),
    });

    if (response.status === 200) {
      return response.data.deletedSignupRequestId;
    } else {
      console.log(response);
      return false;
    }
  } catch (error) {
    console.error(error);
    return false;
  }
});

export const signupRequestsSlice = createSlice({
  name: 'signup-requests',
  initialState,
  reducers: {
    hydrateSignupRequests(state: any, action: any) {
      state.status = 'idle';

      state.signupRequests = action.payload.signupRequests;
    },
    selectSignupRequest(state: any, action: any) {
      state.selectedSignupRequest = action.payload;
    },
  },
  extraReducers: (builder: any) => {
    builder
      .addCase(getSignupRequest.pending, (state: any) => {
        state.status = 'Fetching signup request details';
      })
      .addCase(getSignupRequest.rejected, (state: any, payload: any) => {
        state.status = 'idle';
      })
      .addCase(getSignupRequest.fulfilled, (state: any, action: any) => {
        state.status = 'idle';

        state.selectedClaim = action.payload;
      })
      .addCase(deleteSignupRequest.pending, (state: any) => {
        state.status = 'Deleting signup request';
      })
      .addCase(deleteSignupRequest.rejected, (state: any, payload: any) => {
        state.status = 'idle';
      })
      .addCase(deleteSignupRequest.fulfilled, (state: any, action: any) => {
        state.status = 'idle';

        const id = action.payload;

        let updatedList = [...state.signupRequests];

        updatedList.splice(updatedList.findIndex(e => e._id === id), 1);

        state.signupRequests = updatedList;
      })
      .addCase(listSignupRequests.pending, (state: any) => {
        state.status = 'Fetching signup request list';
      })
      .addCase(listSignupRequests.rejected, (state: any, payload: any) => {
        state.status = 'idle';
      })
      .addCase(listSignupRequests.fulfilled, (state: any, action: any) => {
        state.status = 'idle';

        let updatedList = [...action.payload.data];
        updatedList.map((item) => (item.key = item._id));
        state.signupRequests = updatedList;

        state.stats = action.payload.stats;
      });
  },
});

export const { hydrateSignupRequests, selectSignupRequest } = signupRequestsSlice.actions;

export default signupRequestsSlice.reducer;
