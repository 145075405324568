import { Col, PageHeader, Row, Space, Statistic, Table, Timeline } from 'antd';
import { useAppDispatch, useAppSelector } from '../redux/hook';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getSignupRequest } from '../redux/features/signup-requests/signup-requests.slice';

const SignupRequestDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const {
    selectedSignupRequest: { policyHolder, dependents, files = [], selectedPlan = null, currentStep },
  } = useAppSelector((state) => state.signupRequests);

  useEffect(() => {
    const id = location.pathname.split('/').pop();

    if (id) dispatch(getSignupRequest(id));

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const applicant = `${policyHolder.fullnames} ${policyHolder.surname}`;

  const dependentColumns = [
    {
      title: 'Fullnames',
      dataIndex: 'fullnames',
    },
    {
      title: 'Surname',
      dataIndex: 'surname',
    },
    {
      title: 'Age',
      dataIndex: 'age',
    },
  ];

  const filesColumns = [
    {
      title: 'File Name',
      dataIndex: 'filename',
    },
    {
      title: 'Type',
      dataIndex: 'type',
    },
    {
      title: 'Size',
      dataIndex: 'size',
    },
  ];

  return (
    <>
      <PageHeader className='site-page-header' title={`Signup Request Details`} onBack={() => navigate(-1)}>
        <Row>
          <Col span={24}>
            <Space size={60}>
              <Statistic title='Policy Holder' value={applicant} />
              <Statistic title='Email Address' value={policyHolder.emailAddress} />
              <Statistic title='Progress' value={`Step ${currentStep}`} />
              <Statistic title='Selected Cover Plan' value={selectedPlan?.name ?? 'No plan selected'} />
            </Space>
          </Col>
        </Row>
      </PageHeader>
      <div className='signup-request-details-page page'>
        <div className='container'>
          <h3>Recent Activity</h3>
          <Timeline style={{ margin: '1.5rem 0 0 1.5rem' }}>
            <Timeline.Item color='green'>
              <p style={{ marginBottom: '0' }}>User submitted policy holder details</p>
              <small>
                <i>2022-07-18</i>
              </small>
            </Timeline.Item>
            <Timeline.Item color='blue'>
              <p style={{ marginBottom: '0' }}>Nokuthula Dladla viewed request</p>
              <small>
                <i>2022-07-22</i>
              </small>
            </Timeline.Item>
          </Timeline>
          <h3 style={{ margin: '1rem 0 .5rem 0' }}>Dependents</h3>
          <Table size='small' columns={dependentColumns} dataSource={dependents} />
          <h3 style={{ margin: '1rem 0 .5rem 0' }}>Files</h3>
          <Table size='small' columns={filesColumns} dataSource={files} />
        </div>
      </div>
    </>
  );
};

export default SignupRequestDetails;
