import { Alert, Button, Col, Divider, Form, Image, Input, Row } from 'antd';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import loginImage from '../assets/images/login.png';
import logo from '../assets/images/logo.png';
import { loginUser } from '../redux/features/auth/auth.slice';
import { useAppDispatch, useAppSelector } from '../redux/hook';

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { notify, status, signInRequired, user } = useAppSelector((state) => state.auth);

  const onFinish = (data: any) => {
    dispatch(
      loginUser({
        username: data.username,
        password: data.password,
      })
    );
  };

  useEffect(() => {
    if (!signInRequired && user._id) {
      navigate('/dashboard');
    }

    return () => {};
  }, [signInRequired, navigate, user]);

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <div className='login-page'>
      <div className='container'>
        <div className="logo-container">
          <Image src={logo} preview={false} />
        </div>
        <h1>SOMDAKA FUNERAL SERVICES</h1>
        <Divider style={{ minWidth: '60%', width: '60%', margin: '1rem 20%', borderTop: '1px solid #ffac00' }} />
        <h2>ADMINISTRATION PORTAL</h2>
        {notify && (
          <Alert
            type={notify.type}
            message={notify.message}
            banner
            style={{ width: '50%', margin: '3rem 25% -3rem' }}
          />
        )}
        <Row gutter={16}>
          <Col span={12}>
            <Image src={loginImage} preview={false} />
          </Col>
          <Col span={12}>
            <Form
              layout='vertical'
              name='basic'
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
              initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete='off'
              style={{}}>
              <h2>Sign In</h2>
              <Form.Item
                label='Username'
                name='username'
                rules={[{ required: true, message: 'Please input your username!' }]}>
                <Input />
              </Form.Item>

              <Form.Item
                label='Password'
                name='password'
                rules={[{ required: true, message: 'Please input your password!' }]}>
                <Input.Password />
              </Form.Item>

              {/* <Form.Item name='remember' valuePropName='checked'>
                <Checkbox>Remember me</Checkbox>
              </Form.Item> */}

              <Form.Item>
                <Button type='primary' htmlType='submit' loading={status === 'Authenticating'}>
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Login;
