import { PlusOutlined } from '@ant-design/icons';
import { Button, Form, Input, Modal, Select } from 'antd';
import { useState } from 'react';
import { addList } from '../../redux/features/settings/settings.slice';
import { useAppDispatch } from '../../redux/hook';
import './styles.scss';

const ReferenceListBuilder = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [newList, setNewList] = useState<{
    name: string;
    options: string[];
  }>({
    name: '',
    options: [],
  });

  const dispatch = useAppDispatch();

  const reset = () => {
    setNewList({
      name: '',
      options: [],
    });
    setIsVisible(false);
  };

  const onSubmit = () => {
    dispatch(addList(newList));
    reset();
  };

  const onCancel = () => {
    reset();
  };

  const onChangeOptions = (value: string[]) => {
    setNewList({ ...newList, options: value });
  };

  return (
    <div className='reference-list-builder'>
      <Button size='small' onClick={() => setIsVisible(true)}>
        <PlusOutlined /> Add Reference List
      </Button>
      <Modal title='New Reference List' visible={isVisible} onOk={onSubmit} onCancel={onCancel} destroyOnClose>
        <Form layout='vertical'>
          <Form.Item label='Name'>
            <Input
              value={newList.name}
              placeholder="Enter the name of the list"
              onChange={(event: any) => setNewList({ ...newList, name: event.target.value })}
            />
          </Form.Item>
          <Form.Item label='Options'>
            <Select mode='tags' style={{ width: '100%' }} placeholder='Enter options and click enter' onChange={onChangeOptions}>
              {newList.options}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default ReferenceListBuilder;
