import { Button, Col, Collapse, DatePicker, Form, Input, Modal, PageHeader, Row, Select, Space, Upload } from "antd"
import { useAppDispatch } from "../redux/hook"
import { useEffect, useState } from "react"
import { getClaimDetails } from "../redux/features/claims/claims.slice"
import { useLocation, useNavigate } from "react-router-dom"
import { CloseOutlined, FileAddOutlined, SaveOutlined, UploadOutlined } from "@ant-design/icons"
import { RcFile } from "antd/lib/upload"
import moment from "moment"
import axios from "axios"

const FormItem = Form.Item
const Panel = Collapse.Panel

interface IUploader {
	key: string
	name: string
}

const CreateFuneral = () => {
	const location = useLocation()
	const dispatch = useAppDispatch()

	const navigate = useNavigate()

	const [uploaderVisible, setUploaderVisible] = useState(false)
	const [confirmLoading, setConfirmLoading] = useState(false)
	const [funeral, setFuneral] = useState<any>({
		address: "",
		grave_marker: false,
		is_claim: false,
		policy_number: "",

		bathing_date_time: "",

		delivery_date_time: "",
		familyEscortingDelivery: false,
		arrivalTimeOfFamilyEscortingDelivery: "",

		church_escort: false,
		church_address: "",
		date_time_for_church_escort: "",

		burial_date_time: "",
		cemetery: "",

		coffin: "",
		coffin_image_url: "",

		deceased_name: "",
		deceased_surname: "",

		informant_name: "",
		informant_surname: "",

		informant_phone_number: "",
		informant_whatsapp_number: "",
	})

	const [name, setName] = useState("")

	// const getFuneralDetails = (funeralId: string) => {}

	useEffect(() => {
		// const funeralId = location.pathname.split("/").pop()

		// if (funeralId) getFuneralDetails(funeralId)

		return () => {}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const showUploader = ({ key, name }: IUploader) => {
		setName(name)

		setUploaderVisible(true)
	}

	const handleOk = () => {
		setConfirmLoading(true)
		setTimeout(() => {
			setUploaderVisible(false)
			setConfirmLoading(false)
		}, 5000)
	}

	const handleCancelUpload = () => {
		console.log("Clicked cancel button")
		setUploaderVisible(false)
	}

	const handleSave = async () => {
		console.log("Funeral model => ", funeral)
		const response = await axios.post("https://sdkservices.nexuscore.co.za/api/fb/funerals", funeral)

		if (response.status === 200) {
			if (response.data.success) {
				navigate("/funerals", { replace: true })
			}
		}
	}

	return (
		<>
			<PageHeader
				className='site-page-header'
				title={`Edit Funeral`}
				subTitle='Edit a funeral on the calendar'
				extra={[
					<Button danger onClick={() => navigate("/funerals", { replace: true })}>
						<CloseOutlined /> Cancel
					</Button>,
					<Button type='primary' onClick={handleSave}>
						<SaveOutlined /> Save Changes
					</Button>,
				]}
			/>
			<div className='create-funeral-page page'>
				<div className='container' style={{ paddingLeft: "1rem", paddingRight: "1rem" }}>
					<Form layout='vertical'>
						<Collapse accordion defaultActiveKey='General' bordered={false} expandIconPosition='left'>
							<Panel
								showArrow
								key='General'
								header='General Information'
								extra={
									<Space>
										{/* <Button
                      size='small'
                      type='link'
                      onClick={(event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
                        event.stopPropagation();
                        showUploader({ key: 'death_certificate', name: 'Death Certificate' });
                      }}>
                      <UploadOutlined /> Death Certificate
                    </Button>
                    <Button
                      size='small'
                      type='link'
                      onClick={(event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
                        event.stopPropagation();
                        showUploader({ key: 'burial_order', name: 'Burial Order' });
                      }}>
                      <UploadOutlined /> Burial Order
                    </Button>
                    <Button
                      size='small'
                      type='link'
                      onClick={(event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
                        event.stopPropagation();
                        showUploader({ key: 'bi_1663', name: 'Notification of Death (BI-1663)' });
                      }}>
                      <UploadOutlined /> Notification of Death
                    </Button>
                    <Button
                      size='small'
                      type='link'
                      onClick={(event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
                        event.stopPropagation();
                        showUploader({ key: 'deceased_identification', name: "Deceased's ID or Passport" });
                      }}>
                      <UploadOutlined /> ID / Passport
                    </Button> */}
									</Space>
								}>
								<Row gutter={16} style={{ display: "flex", flexWrap: "wrap" }}>
									<Col span={4}>
										<FormItem label='Funeral Type'>
											<Select
												onSelect={(value: string) => {
													setFuneral({ ...funeral, is_claim: value === "Claim" ? true : false })
												}}
												value={funeral.is_claim ? "Claim" : "Cash"}>
												<Select.Option value='Cash'>Cash</Select.Option>
												<Select.Option value='Claim'>Claim</Select.Option>
											</Select>
										</FormItem>
									</Col>
									{funeral.is_claim && (
										<Col span={4}>
											<FormItem label='Policy Number'>
												<Input
													onChange={(event) => {
														setFuneral({ ...funeral, policy_number: event.target.value })
													}}
													value={funeral.policy_number}
												/>
											</FormItem>
										</Col>
									)}
									<Col span={4}>
										<FormItem label='Grave Marker?'>
											<Select
												onSelect={(value: string) => {
													setFuneral({ ...funeral, grave_marker: value === "True" ? true : false })
												}}
												value={funeral.grave_marker ? "True" : "False"}>
												<Select.Option value='True'>Yes</Select.Option>
												<Select.Option value='False'>No</Select.Option>
											</Select>
										</FormItem>
									</Col>
									<Col span={4}>
										<FormItem label='Coffin Type'>
											<Select
												onSelect={(value: string) => {
													setFuneral({ ...funeral, coffin: value })
												}}
												value={funeral.coffin}>
												<Select.Option value='Flat Lid Coffin'>Flat Lid</Select.Option>
												<Select.Option value='Three Tier Coffin'>Three Tier</Select.Option>
												<Select.Option value='Budget Casket'>Budget Casket</Select.Option>
											</Select>
										</FormItem>
									</Col>
									<Col span={4}>
										<FormItem label='Date of Bathing'>
											<DatePicker
												showTime
												style={{ width: "100%" }}
												value={funeral.bathing_date_time ? moment(funeral.bathing_date_time) : null}
												onChange={(moment) => setFuneral({ ...funeral, bathing_date_time: moment?.toLocaleString() })}
											/>
										</FormItem>
									</Col>
									<Col span={4}>
										<FormItem label='Date of Delivery'>
											<DatePicker
												showTime
												style={{ width: "100%" }}
												value={funeral.delivery_date_time ? moment(funeral.delivery_date_time) : null}
												onChange={(moment) => setFuneral({ ...funeral, delivery_date_time: moment?.toLocaleString() })}
											/>
										</FormItem>
									</Col>
									<Col span={4}>
										<FormItem label='Family Escort (FWC)?'>
											<Select
												onSelect={(value: string) => {
													setFuneral({ ...funeral, familyEscortingDelivery: value === "True" ? true : false })
												}}
												value={funeral.familyEscortingDelivery ? "True" : "False"}>
												<Select.Option value='True'>Yes</Select.Option>
												<Select.Option value='False'>No</Select.Option>
											</Select>
										</FormItem>
									</Col>
									{funeral.familyEscortingDelivery && (
										<Col span={4}>
											<FormItem label='Time of Family Arrival (FWC)'>
												<Input
													onChange={(event) => {
														setFuneral({ ...funeral, arrivalTimeOfFamilyEscortingDelivery: event.target.value })
													}}
													value={funeral.arrivalTimeOfFamilyEscortingDelivery}
												/>
											</FormItem>
										</Col>
									)}
									<Col span={4}>
										<FormItem label='Church Escort?'>
											<Select
												onSelect={(value: string) => {
													setFuneral({ ...funeral, church: value === "True" ? true : false })
												}}
												value={funeral.church ? "True" : "False"}>
												<Select.Option value='True'>Yes</Select.Option>
												<Select.Option value='False'>No</Select.Option>
											</Select>
										</FormItem>
									</Col>
									{funeral.church && (
										<Col span={8}>
											<FormItem label='Church Address'>
												<Input
													onChange={(event) => {
														setFuneral({ ...funeral, church_address: event.target.value })
													}}
													value={funeral.church_address}
												/>
											</FormItem>
										</Col>
									)}
									{funeral.church && (
										<Col span={4}>
											<FormItem label='Date of Church Escort'>
												<DatePicker
													showTime
													style={{ width: "100%" }}
													value={funeral.burial_date_time ? moment(funeral.burial_date_time) : null}
													onChange={(moment) => setFuneral({ ...funeral, date_time_for_church_escort: moment?.toLocaleString() })}
												/>
											</FormItem>
										</Col>
									)}
									<Col span={4}>
										<FormItem label='Date of Burial'>
											<DatePicker
												showTime
												style={{ width: "100%" }}
												value={funeral.burial_date_time ? moment(funeral.burial_date_time) : null}
												onChange={(moment) => setFuneral({ ...funeral, burial_date_time: moment?.toLocaleString() })}
											/>
										</FormItem>
									</Col>
									<Col span={4}>
										<FormItem label='Cemetery'>
											<Input
												onChange={(event) => {
													setFuneral({ ...funeral, cemetery: event.target.value })
												}}
												value={funeral.cemetery}
											/>
										</FormItem>
									</Col>
									<Col span={8}>
										<FormItem label='Burial Address'>
											<Input
												onChange={(event) => {
													setFuneral({ ...funeral, address: event.target.value })
												}}
												value={funeral.address}
											/>
										</FormItem>
									</Col>
								</Row>
							</Panel>
							<Panel
								showArrow
								key='Deceased'
								header='Particulars of the Deceased member'
								extra={
									<Space>
										{/* <Button
                      size='small'
                      type='link'
                      onClick={(event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
                        event.stopPropagation();
                        showUploader({ key: 'death_certificate', name: 'Death Certificate' });
                      }}>
                      <UploadOutlined /> Death Certificate
                    </Button>
                    <Button
                      size='small'
                      type='link'
                      onClick={(event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
                        event.stopPropagation();
                        showUploader({ key: 'burial_order', name: 'Burial Order' });
                      }}>
                      <UploadOutlined /> Burial Order
                    </Button>
                    <Button
                      size='small'
                      type='link'
                      onClick={(event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
                        event.stopPropagation();
                        showUploader({ key: 'bi_1663', name: 'Notification of Death (BI-1663)' });
                      }}>
                      <UploadOutlined /> Notification of Death
                    </Button>
                    <Button
                      size='small'
                      type='link'
                      onClick={(event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
                        event.stopPropagation();
                        showUploader({ key: 'deceased_identification', name: "Deceased's ID or Passport" });
                      }}>
                      <UploadOutlined /> ID / Passport
                    </Button> */}
									</Space>
								}>
								<Row gutter={16}>
									<Col span={6}>
										<FormItem label='Full Names'>
											<Input value={funeral.deceased_name} onChange={(event) => setFuneral({ ...funeral, deceased_name: event.target.value })} />
										</FormItem>
									</Col>
									<Col span={4}>
										<FormItem label='Surname'>
											<Input value={funeral.deceased_surname} onChange={(event) => setFuneral({ ...funeral, deceased_surname: event.target.value })} />
										</FormItem>
									</Col>
									<Col span={4}>
										<FormItem label='Identification Type'>
											<Select
												value={funeral.deceased_identification_type}
												onSelect={(value: any) => setFuneral({ ...funeral, deceased_identification_type: value })}>
												<Select.Option value='South African ID'>South African ID Number</Select.Option>
												<Select.Option value='Passport'>Passport Number</Select.Option>
											</Select>
										</FormItem>
									</Col>
									<Col span={4}>
										<FormItem label='Identification Number'>
											<Input
												value={funeral.deceased_identification_number}
												onChange={(event) => setFuneral({ ...funeral, deceased_identification_number: event.target.value })}
											/>
										</FormItem>
									</Col>
									{/* <Col span={3}>
                    <FormItem label='Date of Birth'>
                      <DatePicker />
                    </FormItem>
                  </Col>
                  <Col span={3}>
                    <FormItem label='Date of Death'>
                      <DatePicker />
                    </FormItem>
                  </Col> */}
								</Row>
							</Panel>
							<Panel showArrow key='Informant' header='Particulars of the Informant'>
								<Row gutter={16}>
									<Col span={4}>
										<FormItem label='Full Names'>
											<Input value={funeral.informant_name} onChange={(event) => setFuneral({ ...funeral, informant_name: event.target.value })} />
										</FormItem>
									</Col>
									<Col span={4}>
										<FormItem label='Surname'>
											<Input value={funeral.informant_surname} onChange={(event) => setFuneral({ ...funeral, informant_surname: event.target.value })} />
										</FormItem>
									</Col>
									<Col span={4}>
										<FormItem label='Identification Type'>
											<Select
												value={funeral.informant_identification_type}
												onSelect={(value: any) => setFuneral({ ...funeral, informant_identification_type: value })}>
												<Select.Option value='South African ID'>South African ID Number</Select.Option>
												<Select.Option value='Passport'>Passport Number</Select.Option>
											</Select>
										</FormItem>
									</Col>
									<Col span={4}>
										<FormItem label='Identification Number'>
											<Input
												value={funeral.informant_identification_number}
												onChange={(event) => setFuneral({ ...funeral, informant_identification_number: event.target.value })}
											/>
										</FormItem>
									</Col>
									<Col span={4}>
										<FormItem label='Phone Number'>
											<Input
												value={funeral.informant_phone_number}
												onChange={(event) => setFuneral({ ...funeral, informant_phone_number: event.target.value })}
											/>
										</FormItem>
									</Col>
									<Col span={4}>
										<FormItem label='WhatsApp Number'>
											<Input
												value={funeral.informant_whatsapp_number}
												onChange={(event) => setFuneral({ ...funeral, informant_whatsapp_number: event.target.value })}
											/>
										</FormItem>
									</Col>
								</Row>
							</Panel>
						</Collapse>
					</Form>
				</div>
				<Modal
					title='Upload a file'
					visible={uploaderVisible}
					onOk={handleOk}
					confirmLoading={confirmLoading}
					footer={false}
					onCancel={handleCancelUpload}>
					{/* <p>{uploaderText}</p> */}
					<Upload
						action='uploadUrl'
						name='files'
						showUploadList={false}
						beforeUpload={(file: RcFile) => {
							// handleFileUpload(file);
							return false
						}}>
						<Button type='primary' style={{ margin: "0" }}>
							<FileAddOutlined /> Upload {name}
						</Button>
					</Upload>
				</Modal>
			</div>
		</>
	)
}

export default CreateFuneral
