import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { CLIENT_SERVICE_URL } from '../../../app-constants';
import { IFaq } from '../../../models/faq.model';
import { getAuthHeader } from '../../helpers';

// Define a type for the slice state
interface FAQsState {
  faqs: any[];
  count: number;
  currentPage: number;
  totalPages: number;
  status: string;
}

// Define the initial state using that type
const initialState: FAQsState = {
  faqs: [],
  count: 0,
  currentPage: 0,
  totalPages: 0,
  status: 'idle',
};

export const addFAQ = createAsyncThunk('faqs/add', async (newFAQ: IFaq) => {
  try {
    const response = await axios.post(`${CLIENT_SERVICE_URL}faqs`, newFAQ, {
      headers: getAuthHeader(),
    });

    if (response.status === 200) {
      return response.data;
    } else {
      console.log(response);
      return false;
    }
  } catch (error) {
    console.error(error);
    return false;
  }
});

export const getFAQs = createAsyncThunk('faqs/list', async () => {
  try {
    const response = await axios.get(`${CLIENT_SERVICE_URL}faqs`, {
      headers: getAuthHeader(),
    });

    if (response.status === 200) {
      return response.data;
    } else {
      console.log(response);
      return false;
    }
  } catch (error) {
    console.error(error);
    return false;
  }
});

export const searchFAQs = createAsyncThunk('faqs/search', async (text: string) => {
  try {
    const response = await axios.post(
      `${CLIENT_SERVICE_URL}faqs/search`,
      { searchText: text },
      {
        headers: getAuthHeader(),
      }
    );

    if (response.status === 200) {
      return response.data;
    } else {
      console.log(response);
      return false;
    }
  } catch (error) {
    console.error(error);
    return false;
  }
});

export const faqsSlice = createSlice({
  name: 'faqs',
  initialState,
  reducers: {
    hydrateFAQs(state: any, action: any) {
      state.faqs = action.payload.faqs;
      state.currentPage = action.payload.currentPage;
      state.totalPages = action.payload.totalPages;
    },
  },
  extraReducers: (builder: any) => {
    builder
      .addCase(addFAQ.pending, (state: any) => {
        state.status = 'Adding new FAQ';
      })
      .addCase(addFAQ.rejected, (state: any) => {
        state.status = 'idle';
      })
      .addCase(addFAQ.fulfilled, (state: any, action: any) => {
        state.status = action.payload.message;

        let updatedFAQs = [...state.faqs, action.payload.faq];
        state.faqs = updatedFAQs;
      })
      .addCase(searchFAQs.pending, (state: any) => {
        state.status = 'Searching FAQs';
      })
      .addCase(searchFAQs.rejected, (state: any) => {
        state.status = 'idle';
      })
      .addCase(searchFAQs.fulfilled, (state: any, action: any) => {
        state.status = 'idle';

        let updatedItems = [...action.payload];
        updatedItems.map((item) => (item.key = item._id));

        state.faqs = updatedItems;
      })
      .addCase(getFAQs.pending, (state: any) => {
        state.status = 'Fetching FAQs';
      })
      .addCase(getFAQs.rejected, (state: any) => {
        state.status = 'idle';
      })
      .addCase(getFAQs.fulfilled, (state: any, action: any) => {
        state.status = 'idle';
        state.count = action.payload.count;

        let updatedItems = [...action.payload.faqs];
        updatedItems.map((item) => (item.key = item._id));

        state.faqs = updatedItems;
        state.currentPage = action.payload.currentPage;
        state.totalPages = action.payload.totalPages;
      });
  },
});

export const { hydrateFAQs } = faqsSlice.actions;

export default faqsSlice.reducer;
