import { EyeOutlined } from '@ant-design/icons';
import { Button, Image, Tooltip } from 'antd';
import React, { useState } from 'react';

interface Props {
  url: string;
}

const ImageLink = ({ url }: Props) => {
  const [visible, setVisible] = useState(false);
  return (
    <>
      <Tooltip title='Preview'>
        <Button size='small' type='link' onClick={() => setVisible(true)}>
          <EyeOutlined />
        </Button>
      </Tooltip>
      <Image
        style={{
          display: 'none',
        }}
        src={url}
        preview={{
          visible,
          src: url,
          onVisibleChange: (value) => {
            setVisible(value);
          },
        }}
      />
    </>
  );
};

export default ImageLink;
