export const CLIENT_NAME = 'Somdaka Funeral Services';
export const APP_NAME = `${CLIENT_NAME} - Administration Portal`;

export const CLIENT_ID = '627c109a24c1b3a8ab9f56ac';

export const LOCAL_STORAGE_KEY = 'SOMDAKA_FUNERAL_SERVICES';

export const SESSION_KEY__TOKEN = 'token';
export const SESSION_KEY__USER = 'user';

export const CLIENT_ZONE_URL = 'https://clientzone.nexuscore.co.za/api/';

// export const BASE_URL = 'http://localhost:8103/';
export const BASE_URL = 'https://sdkservices.nexuscore.co.za/';

// export const CLIENT_SERVICE_URL = 'http://localhost:8103/api/';
export const CLIENT_SERVICE_URL = 'https://sdkservices.nexuscore.co.za/api/';

export enum APP_THEME {
  DARK = 'DARK',
  LIGHT = 'LIGHT',
}

export interface IRoute {
  id: number;
  name: string;
  url: string;
  key: string;
  requiresAdmin: boolean;
  group: number;
  active: boolean;
}

export const ROUTES: IRoute[] = [
  {
    id: 1,
    name: 'Dashboard',
    url: '/dashboard',
    key: 'dashboard',
    requiresAdmin: false,
    group: 1,
    active: true,
  },
  {
    id: 2,
    name: 'Claims',
    url: '/claims',
    key: 'claims',
    group: 2,
    requiresAdmin: false,
    active: false,
  },
  {
    id: 3,
    name: 'Policies',
    url: '/policies',
    key: 'policies',
    group: 2,
    requiresAdmin: true,
    active: true,
  },
  {
    id: 4,
    name: 'EFT Transactions',
    url: '/eft-transactions',
    key: 'eft-transactions',
    group: 1,
    requiresAdmin: false,
    active: true,
  },
  {
    id: 5,
    name: 'Easypay Transactions',
    url: '/easypay-transactions',
    key: 'easypay-transactions',
    group: 1,
    requiresAdmin: false,
    active: true,
  },
  {
    id: 5,
    name: 'Receipt Management',
    url: '/receipt-management',
    key: 'receipt-management',
    group: 1,
    requiresAdmin: true,
    active: true,
  },
  {
    id: 6,
    name: 'Signups',
    url: '/signup-requests',
    key: 'signup-requests',
    group: 2,
    requiresAdmin: false,
    active: false,
  },
  {
    id: 7,
    name: 'Documents',
    url: '/documents',
    key: 'documents',
    group: 3,
    requiresAdmin: false,
    active: true,
  },
  {
    id: 8,
    name: 'Graves',
    url: '/graves',
    key: 'graves',
    group: 2,
    requiresAdmin: false,
    active: true,
  },
  {
    id: 9,
    name: 'Terms',
    url: '/terms',
    key: 'terms',
    group: 3,
    requiresAdmin: true,
    active: true,
  },
  {
    id: 10,
    name: 'FAQs',
    url: '/faqs',
    key: 'faqs',
    group: 3,
    requiresAdmin: true,
    active: true,
  },
  {
    id: 11,
    name: 'Users',
    url: '/users',
    key: 'users',
    group: 4,
    requiresAdmin: true,
    active: true,
  },
  {
    id: 12,
    name: 'Settings',
    url: '/settings',
    key: 'settings',
    group: 4,
    requiresAdmin: true,
    active: true,
  },
  {
    id: 13,
    name: 'Mortuary Register',
    url: '/mortuary-register',
    key: 'mortuary-register',
    requiresAdmin: false,
    group: 1,
    active: false,
  },
  {
    id: 14,
    name: 'Funerals',
    url: '/funerals',
    key: 'funerals',
    group: 2,
    requiresAdmin: false,
    active: true,
  },
];
