import { CloseCircleFilled } from "@ant-design/icons"
import { DatePicker, notification, Select, Space } from "antd"
import { useState, useRef } from "react"
import * as XLSX from "xlsx"
import { importExcelTransactions } from "../../redux/features/transactions/eft.slice"
import { useAppDispatch } from "../../redux/hook"

interface IProps {
	buckets: any
	setBuckets: (updatedBuckets: any) => void
}

export const AndyImportTool = ({ buckets, setBuckets }: IProps) => {
	const fileRef = useRef() as React.MutableRefObject<HTMLInputElement>

	const [fileType, setFileType] = useState<string>()
	const [fileName, setFileName] = useState(null)
	const [statementMonth, setStatementMonth] = useState<any>(undefined)

	const acceptableFileTypes = ["xlsx", "xls"]

	const isAcceptableFile = (name: string) => {
		let extension = name.split(".").pop()?.toLowerCase()

		if (extension) return acceptableFileTypes.includes(extension)

		return false
	}

	const parseExcelData = (data: any) => {
		const workbook = XLSX.read(data)

		let _sheetData: any[] = []

		for (let index = 0; index < workbook.SheetNames.length; index++) {
			let sheetName = workbook.SheetNames[index]

			const worksheet = workbook.Sheets[sheetName]
			const jsonData = XLSX.utils.sheet_to_json(worksheet)

			if (jsonData.length > 0) {
				_sheetData = [..._sheetData, ...jsonData]
			}
		}

		let payload = { statementMonth: statementMonth, transactions: _sheetData, uuid: statementMonth }

		if (fileType == "assitReceipts") {
			setBuckets((prev: any) => ({
				...prev,
				assitReceipts: [...prev.assitReceipts, ...payload.transactions],
			}))
		}

		if (fileType == "assitCreditNotes") {
			setBuckets((prev: any) => ({
				...prev,
				assitCreditNotes: [...prev.assitCreditNotes, ...payload.transactions],
			}))
		}

		if (fileType == "easipolEasypayReceipts") {
			setBuckets((prev: any) => ({
				...prev,
				easipolEasypayReceipts: [...prev.easipolEasypayReceipts, ...payload.transactions],
			}))
		}
	}

	const handleFile = async (event: any) => {
		const _file = event.target.files[0]

		if (!_file) return

		if (!isAcceptableFile(_file.name)) {
			notification.error({
				message: "Invalid File Type",
				description: "Please upload xlsx or xls files only",
			})
			return
		}

		setFileName(_file.name)

		const data = await _file.arrayBuffer()

		parseExcelData(data)
	}

	const handleRemoveFile = () => {
		setStatementMonth(undefined)
		setFileName(null)
		fileRef.current.value = ""
	}

	const onMonthChange = (_date: any, dateString: string) => {
		if (dateString.length === 0) {
			setStatementMonth(undefined)
		} else {
			setStatementMonth(dateString)
		}
	}

	return (
		<div>
			{fileName && (
				<label style={{ color: "#999", textTransform: "uppercase", letterSpacing: "0.15rem" }}>
					File Name: <span style={{}}>{fileName}</span> <CloseCircleFilled onClick={handleRemoveFile} style={{ color: "#ff4d4f", cursor: "pointer" }} />
				</label>
			)}
			{/* {!fileName && <label style={{ color: "#999", textTransform: "uppercase", letterSpacing: "0.15rem" }}>Import Excel File</label>} */}
			<Space style={{ display: "flex", justifyContent: "flex-end" }}>
				<Select style={{ width: "260px" }} onSelect={(value: string) => setFileType(value)}>
					<Select.Option value='assitReceipts'>Assit - Receipts</Select.Option>
					<Select.Option value='assitCreditNotes'>Assit - Credit Notes</Select.Option>
					<Select.Option value='easipolEasypayReceipts'>Easipol - Easipay Receipts</Select.Option>
				</Select>
				<input
					type='file'
					name='file'
					multiple={false}
					accept='xlsx, xls'
					ref={fileRef}
					onChange={handleFile}
					style={{ display: "block", margin: "10px 0" }}
				/>
			</Space>
		</div>
	)
}
