import { Col, PageHeader, Row, Space, Statistic, Table, Timeline } from "antd"
import { useAppDispatch, useAppSelector } from "../redux/hook"
import { useEffect } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { getTermsOfServiceById } from "../redux/features/terms-of-service/terms-of-service.slice"

const TermsDetails = () => {
	const location = useLocation()
	const navigate = useNavigate()

	const dispatch = useAppDispatch()
	const { selectedTermsOfService } = useAppSelector((state) => state.termsOfService)

	useEffect(() => {
		const id = location.pathname.split("/").pop()

		if (id) dispatch(getTermsOfServiceById(id))

		return () => {}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<>
			<PageHeader className='site-page-header' title={selectedTermsOfService?.title} onBack={() => navigate(-1)} />
			<div className='terms-details-page page'>
				<div className='container' style={{ padding: '2rem' }}>
          {selectedTermsOfService?.sections.map(section => <div style={{ marginBottom: '2rem' }}>
            <h3>{section.title}</h3>
            {section.paragraphs.map(paragraph => <p>{paragraph}</p>)}
          </div>)}
        </div>
			</div>
		</>
	)
}

export default TermsDetails
