import { Col, PageHeader, Row, Statistic, Table, Input, Form, Empty, Button, Space, Select, Modal, Spin, Alert, Drawer, List } from "antd"
import Papa from "papaparse"
import { useEffect, useState } from "react"
import {
	clearTransactions,
	deleteTransactions,
	getImportData,
	importTransactions,
	searchByAmount,
	searchTransactions,
} from "../redux/features/transactions/easypay.slice"
import { useAppDispatch, useAppSelector } from "../redux/hook"
import { formatToMoneyWithCurrency, formatUCTtoISO } from "../utils/formatters"
import searchImage from "../assets/images/search.png"
import { DeleteOutlined, ExclamationCircleOutlined, LoadingOutlined } from "@ant-design/icons"
import { IEasypayImport } from "../interfaces/IEasypayImport"
import { IEasypayImportData } from "../interfaces/IEasypayImportData"
import { CsvImportTool } from "../components/csvImportTool"

const Search = Input.Search
const Option = Select.Option
const { confirm } = Modal

const EasypayTransactions = () => {
	const dispatch = useAppDispatch()
	const { transactions, count, status, hasError, errorMessage, importData } = useAppSelector((state) => state.easypay)
	const { isAdmin, user } = useAppSelector((state) => state.auth)

	const [search, setSearch] = useState("")
	const [amountFilterType, setAmountFilterType] = useState("=")
	const [amount, setAmount] = useState<number | string>("")

	const [showImportHistory, setShowImportHistory] = useState(false)

	const clearFields = () => {
		setSearch("")
		setAmount("")
		setAmountFilterType("=")
	}

	const handleImportHistoryShow = () => {
		dispatch(getImportData())
			.then((result) => {
				if (result) {
					setShowImportHistory(true)
				}
			})
			.catch((err) => {
				console.log(err)
			})
	}

	const parseFiles = (files: any) => {
		const importBatch: any[] = []

		Promise.all(
			[...files].map(
				(file) =>
					new Promise((resolve, reject) =>
						Papa.parse(file, {
							skipEmptyLines: true,
							complete: function (results: any) {
								const data = results.data

								if (data) {
									let statementDate = data[0][3]
									const _uuid = data[0][3]
									const _year = statementDate.substr(0, 4)
									const _month = statementDate.substr(4, 2)
									const _day = statementDate.substr(6, 2)

									statementDate = `${_year}/${_month}/${_day}`

									let _transactions: any[] = []

									data.forEach((child: any[], index: number) => {
										if (child[0] === "P") {
											_transactions.push({
												uuid: _uuid,
												date: statementDate,
												amount: child[1].trim(),
												easypayNumber: child[3],
											})
										}
									})

									const _numberOfTransactions = _transactions.length

									let payload: IEasypayImport = {
										transactions: _transactions,
										importData: {
											uuid: _uuid,
											date: statementDate,
											numberOfTransactions: _numberOfTransactions,
											createdBy: `${user.fullnames}`,
										},
									}

									resolve(payload)
								}
							}, // Resolve each promise
							error: reject,
						})
					)
			)
		)
			.then((results) => {
				results.forEach((result, index) => {
					importBatch.push(result)
				})
				dispatch(importTransactions(importBatch)) // now since .then() excutes after all promises are resolved, filesData contains all the parsed files.
			})
			.catch((err) => console.error("Something went wrong:", err))
	}

	const fileChangeHandler = async (event: any) => {
		parseFiles(event.target.files)
	}

	useEffect(() => {
		dispatch(clearTransactions())

		return () => {}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const columns = [
		{
			title: "File Number",
			dataIndex: "uuid",
		},
		{
			title: "Transaction Date",
			dataIndex: "date",
		},
		{
			title: "Easypay Number",
			dataIndex: "easypayNumber",
		},
		{
			title: "Amount",
			dataIndex: "amount",
			render: (text: number) => <span>{formatToMoneyWithCurrency(text)}</span>,
		},
	]

	const showDeleteConfirm = () => {
		confirm({
			title: "Are you sure you want to delete all selected transactions?",
			icon: <ExclamationCircleOutlined />,
			content: "This action is irreversible and deletes transactions from the database.",
			okText: "Yes, I am sure",
			okType: "danger",
			cancelText: "No",
			onOk() {
				dispatch(deleteTransactions())

				setTimeout(() => {
					clearFields()
				}, 200)
			},
			onCancel() {
				console.log("Cancel")
			},
		})
	}

	const [timeElapsed, setTimeElapsed] = useState(0)
	const [start, setStart] = useState(false)

	useEffect(() => {
		let interval: any = null

		if (start) {
			interval = setInterval(() => {
				setTimeElapsed((prevTime) => prevTime + 10)
			}, 10)
		} else {
			clearInterval(interval)
		}

		return () => clearInterval(interval)
	}, [start])

	useEffect(() => {
		if (status !== "idle") setStart(true)
		if (status === "idle") {
			setStart(false)
			setTimeElapsed(0)
		}

		return () => {}
	}, [status])

	const handleEasypayNumberSearch = (value: string) => {
		if (value.length > 0) {
			dispatch(searchTransactions(value))
		} else {
			dispatch(clearTransactions())
			clearFields()
		}
	}

	const handleAmountSearch = (value: string) => {
		if (value.length > 0) {
			dispatch(
				searchByAmount({
					amount: amount,
					filterType: amountFilterType,
				})
			)
		} else {
			dispatch(clearTransactions())
			clearFields()
		}
	}

	return (
		<>
			<Spin
				tip={
					<h3>
						{status}
						<br />
						{start && (
							<>
								<span className='digits'>{("0" + Math.floor((timeElapsed / 60000) % 60)).slice(-2)}:</span>
								<span className='digits'>{("0" + Math.floor((timeElapsed / 1000) % 60)).slice(-2)}.</span>
								<span className='digits mili-sec'>{("0" + ((timeElapsed / 10) % 100)).slice(-2)}</span>
							</>
						)}
					</h3>
				}
				spinning={status !== "idle"}
				size='large'
				indicator={<LoadingOutlined />}>
				<PageHeader
					className='site-page-header'
					title='Easypay Transactions'
					extra={[
						<Space>
							<Button onClick={handleImportHistoryShow}>Import History</Button>
						</Space>,
					]}>
					<Row gutter={16}>
						<Col span={12}>
							<Space size={32}>
								<Statistic title='Total Transactions' value={count} />
								<Statistic title='Selected Transactions' value={transactions?.length} />
							</Space>
						</Col>
						<Col span={12} style={{ display: "flex", justifyContent: "flex-end" }}>
							{isAdmin && (
								<Space size={32}>
									<CsvImportTool handleChange={fileChangeHandler} allowMultiple label='Import Easypay Statement' />
								</Space>
							)}
						</Col>
					</Row>
				</PageHeader>

				<Form layout='vertical' style={{ width: "100%", borderBottom: "1px solid #ddd", padding: "2rem 1rem 3rem 1rem" }}>
					{hasError && (
						<div style={{ padding: "0 34.5% 2rem 34.5%" }}>
							<Alert showIcon type={hasError ? "error" : "info"} message={hasError ? errorMessage : ""} />
						</div>
					)}
					<Space size={32} wrap style={{ display: "flex", justifyContent: "flex-start", paddingBottom: "0", width: "100%" }}>
						<Form.Item style={{ marginBottom: "0" }}>
							<p style={{ marginBottom: ".5rem", textTransform: "uppercase", letterSpacing: ".2rem" }}>Search by Easypay Number</p>
							<Search
								allowClear
								value={search}
								placeholder='Search Text...'
								onChange={(event: any) => (event.target.value === "" ? setSearch("") : setSearch(event.target.value))}
								onSearch={handleEasypayNumberSearch}
							/>
						</Form.Item>
						<Form.Item style={{ marginBottom: "0" }}>
							<p style={{ marginBottom: ".5rem", textTransform: "uppercase", letterSpacing: ".2rem" }}>Search for Amount</p>
							<Space>
								<Search
									allowClear
									defaultValue={""}
									value={amount}
									placeholder='1000'
									onChange={(event: any) => (event.target.value === "" ? setAmount("") : setAmount(Number(event.target.value)))}
									addonBefore={
										<Select
											value={amountFilterType}
											onChange={(value: string) => setAmountFilterType(value)}
											showArrow={false}
											defaultValue={"="}
											style={{ width: "6rem" }}>
											<Option value={"="}>=</Option>
											<Option value={">"}>&gt;</Option>
											<Option value={"<"}>&lt;</Option>
										</Select>
									}
									onSearch={handleAmountSearch}
								/>
							</Space>
						</Form.Item>
						{isAdmin && transactions.length > 0 && (
							<Form.Item style={{ marginBottom: "0", marginTop: "2.5rem" }}>
								<Space>
									<Button loading={status === "Deleting selected transactions"} onClick={showDeleteConfirm} danger>
										<DeleteOutlined /> Permanently Delete Selected
									</Button>
								</Space>
							</Form.Item>
						)}
					</Space>
				</Form>

				<div className='transactions-page page' style={{ minHeight: "67vh", overflow: "scroll" }}>
					<div className='container'>
						<Spin tip={status} spinning={status === "Deleting selected transactions"}>
							{transactions.length > 0 ? (
								<Table
									columns={columns}
									dataSource={transactions}
									size='small'
									// pagination={{
									//   position: ['topRight', 'bottomRight'],
									//   // onChange: handlePageChange,
									//   total: count,
									//   pageSize: transactions.length,
									// }}
								/>
							) : (
								<div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
									<Empty
										image={searchImage}
										imageStyle={{
											height: 420,
										}}
										description={<h3 style={{ fontWeight: "normal", margin: "2rem 0 0 0" }}>Search transactions by Easypay Number or transaction amount...</h3>}
									/>
								</div>
							)}
						</Spin>
					</div>
					<Drawer title='Import History' placement='right' closable={true} onClose={() => setShowImportHistory(false)} visible={showImportHistory}>
						<List
							itemLayout='horizontal'
							dataSource={importData?.imports}
							renderItem={(item: IEasypayImportData) => (
								<List.Item
									extra={[
										<Space>
											{/* {isAdmin && (
                        <DeleteOutlined
                          onClick={() => handleReverseImport(item)}
                          style={{ color: 'red', cursor: 'pointer' }}
                        />
                      )} */}
										</Space>,
									]}>
									<List.Item.Meta
										title={`Import ${item.uuid}`}
										description={
											<>
												<small style={{ display: "block" }}>{item.numberOfTransactions} Transactions</small>
												<small style={{ display: "block" }}>
													Imported by {item.createdBy} on {formatUCTtoISO(`${item.created_at}`)}
												</small>
											</>
										}
									/>
								</List.Item>
							)}
						/>
					</Drawer>
				</div>
			</Spin>
		</>
	)
}

export default EasypayTransactions
