import {
	PageHeader,
	Table,
	Input,
	Form,
	Empty,
	Spin,
	Button,
	Modal,
	Image,
	Select,
	Row,
	Col,
	DatePicker,
	Space,
	Tooltip,
} from 'antd';
import { useEffect, useState } from 'react';
import {
	addGrave,
	deleteGrave,
	getGraves,
	searchGraves,
	setSelectedGrave,
	updateGrave,
} from '../redux/features/graves/graves.slice';
import { useAppDispatch, useAppSelector } from '../redux/hook';
import { formatUCTtoISO } from '../utils/formatters';
import graveImage from '../assets/images/grave.png';
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { IGrave } from '../models/grave.model';
import moment from 'moment';

const Search = Input.Search;
const FormItem = Form.Item;
const Option = Select.Option;

const Graves = () => {
	const dispatch = useAppDispatch();
	const [visible, setVisible] = useState(false);
	const [mode, setMode] = useState('create');
	const [grave, setGrave] = useState<IGrave>({
		names: '',
		surname: '',
		idNumber: '',
		passportNumber: '',
		address: '',
		phoneNumber: '',
		cemetery: '',
		block: '',
		graveNumber: '',
		longitude: '',
		latitude: '',
		burialDate: '',
	});
	const { graves, selectedGrave, count, status } = useAppSelector((state) => state.graves);

	const [search, setSearch] = useState('');

	const cemeteries = ['Bredel', 'Moofontein', 'Ililiba', 'Waterfall', 'Midrand', 'Johannesburg'];

	useEffect(() => {
		dispatch(getGraves());

		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (selectedGrave) {
			setGrave(selectedGrave);
		}
	
		return () => {}
	}, [selectedGrave])
	

	const columns: any = [
		{
			title: 'Full Names',
			dataIndex: 'names',
		},
		{
			title: 'Surname',
			dataIndex: 'surname',
		},
		{
			title: 'ID Number',
			dataIndex: 'idNumber',
		},
		{
			title: 'Passport Number',
			dataIndex: 'passportNumber',
		},
		{
			title: 'Address',
			dataIndex: 'address',
		},
		{
			title: 'Burial Date',
			dataIndex: 'burialDate',
			render: (text: string) => <span>{text !== '' ? formatUCTtoISO(text) : '---'}</span>,
		},
		{
			title: 'Cemetery',
			dataIndex: 'cemetery',
		},
		{
			title: 'Block',
			dataIndex: 'block',
		},
		{
			title: 'Grave Number',
			dataIndex: 'graveNumber',
		},
		{
			title: 'Date Created',
			dataIndex: 'created_at',
			render: (text: string) => <span>{formatUCTtoISO(text)}</span>,
		},
		{
			title: 'Actions',
			render: (text: string, record: any, index: number) => {
				return (
					<Space>
						<Tooltip title='Edit'>
							<Button
								size='small'
								type='link'
								onClick={() => {
									dispatch(setSelectedGrave(record));
									setMode('edit');
									setVisible(true);
								}}
							>
								<EditOutlined />
							</Button>
						</Tooltip>
						<Tooltip title='Delete'>
							<Button size='small' type='link' danger onClick={() => dispatch(deleteGrave(record.key))}>
								<DeleteOutlined />
							</Button>
						</Tooltip>
					</Space>
				);
			},
		},
	];

	const handlePageChange = (page: number, pageSize: number) => {
		dispatch(getGraves(page.toString()));
	};

	// useEffect(() => {
	//   if (status === 'Grave created successfully' && visible && newGrave.question.length > 0 && newGrave.answer.length > 0) {
	//     setNewGrave({
	//       question: '',
	//       answer: '',
	//     });

	//     setVisible(false);
	//   }

	//   return () => {};
	//   // eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [status]);

	const handleSubmit = () => {
		if (mode === 'create') {
			dispatch(addGrave(grave));
		} else {
			dispatch(updateGrave(grave));
		}
		setVisible(false);
	};

	return (
		<>
			<PageHeader
				className='site-page-header'
				title={
					<span>
						<Image src={graveImage} height={32} /> Graves
					</span>
				}
				extra={[
					<Button key='1' onClick={() => setVisible(true)}>
						<PlusOutlined /> Add Grave
					</Button>,
				]}
			></PageHeader>
			<Form layout='vertical' style={{ width: '100%', borderBottom: '1px solid #ddd', padding: '2rem 1rem 3rem 1rem' }}>
				<Form.Item style={{ marginBottom: '0', width: '27%' }}>
					<p style={{ marginBottom: '.5rem', textTransform: 'uppercase', letterSpacing: '.2rem' }}>Search</p>
					<Search
						allowClear
						value={search}
						placeholder='Enter keyword...'
						onChange={(event: any) => setSearch(event.target.value)}
						onSearch={(value: string) => dispatch(searchGraves(value))}
					/>
				</Form.Item>
			</Form>
			<div className='graves-page page' style={{ minHeight: '67vh' }}>
				<div className='container'>
					<Spin tip={status} spinning={status === 'Deleting selected graves'}>
						{graves.length > 0 ? (
							<Table
								columns={columns}
								dataSource={graves}
								size='small'
								pagination={{
									position: ['bottomRight'],
									onChange: handlePageChange,
									total: count,
									pageSize: graves.length,
								}}
							/>
						) : (
							<div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
								<Empty
									image={graveImage}
									imageStyle={{
										height: 240,
										padding: '2rem',
									}}
									description={<h3 style={{ fontWeight: 'normal', margin: '2rem 0 0 0' }}>Search graves...</h3>}
								/>
							</div>
						)}
					</Spin>
				</div>
				<Modal
					title={mode === 'create' ? 'Add Grave' : 'Edit Grave'}
					centered
					destroyOnClose={true}
					visible={visible}
					onOk={handleSubmit}
					onCancel={() => setVisible(false)}
					width={1000}
				>
					<Form layout='vertical'>
						<h5 style={{ textTransform: 'uppercase' }}>Details of the Deceased:</h5>
						<Row gutter={16}>
							<Col span={6}>
								<FormItem label='Names'>
									<Input
										value={grave.names}
										onChange={(event: any) => setGrave({ ...grave, names: event.target.value })}
									/>
								</FormItem>
							</Col>
							<Col span={6}>
								<FormItem label='Surname'>
									<Input
										value={grave.surname}
										onChange={(event: any) => setGrave({ ...grave, surname: event.target.value })}
									/>
								</FormItem>
							</Col>
							<Col span={6}>
								<FormItem label='ID Number'>
									<Input
										value={grave.idNumber}
										onChange={(event: any) => setGrave({ ...grave, idNumber: event.target.value })}
									/>
								</FormItem>
							</Col>
							<Col span={6}>
								<FormItem label='Passport Number'>
									<Input
										value={grave.passportNumber}
										onChange={(event: any) => setGrave({ ...grave, passportNumber: event.target.value })}
									/>
								</FormItem>
							</Col>
						</Row>
						<Row gutter={16}>
							<Col span={6}>
								<FormItem label='Burial Date'>
									<DatePicker
										style={{ width: '100%' }}
										value={grave.burialDate ? moment(grave.burialDate) : undefined}
										onChange={(date: any, value: string) => setGrave({ ...grave, burialDate: value })}
									/>
								</FormItem>
							</Col>
							<Col span={12}>
								<FormItem label='Address'>
									<Input
										value={grave.address}
										onChange={(event: any) => setGrave({ ...grave, address: event.target.value })}
									/>
								</FormItem>
							</Col>
							<Col span={6}>
								<FormItem label='Phone Number'>
									<Input
										value={grave.phoneNumber}
										onChange={(event: any) => setGrave({ ...grave, phoneNumber: event.target.value })}
									/>
								</FormItem>
							</Col>
						</Row>
						<h5 style={{ textTransform: 'uppercase' }}>Details of the Grave:</h5>
						<Row gutter={16}>
							<Col span={6}>
								<FormItem label='Cemetery'>
									<Select value={grave.cemetery} onChange={(value: any) => setGrave({ ...grave, cemetery: value })}>
										{cemeteries.map((cemetery) => (
											<Option key={cemetery} value={cemetery}>
												{cemetery}
											</Option>
										))}
									</Select>
								</FormItem>
							</Col>
							<Col span={3}>
								<FormItem label='Block #'>
									<Input
										value={grave.block}
										onChange={(event: any) => setGrave({ ...grave, block: event.target.value })}
									/>
								</FormItem>
							</Col>
							<Col span={3}>
								<FormItem label='Grave #'>
									<Input
										value={grave.graveNumber}
										onChange={(event: any) => setGrave({ ...grave, graveNumber: event.target.value })}
									/>
								</FormItem>
							</Col>
							<Col span={6}>
								<FormItem label='Longitude'>
									<Input
										type='number'
										value={grave.longitude}
										onChange={(event: any) => setGrave({ ...grave, longitude: event.target.value })}
									/>
								</FormItem>
							</Col>
							<Col span={6}>
								<FormItem label='Latitude'>
									<Input
										type='number'
										value={grave.latitude}
										onChange={(event: any) => setGrave({ ...grave, latitude: event.target.value })}
									/>
								</FormItem>
							</Col>
						</Row>
					</Form>
				</Modal>
			</div>
		</>
	);
};

export default Graves;
