import { Col, PageHeader, Row, Statistic, Table, Input, Form, Empty, Spin, Button, Modal, Space, Tooltip } from "antd"
import { useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from "../redux/hook"
import { formatUCTtoISO } from "../utils/formatters"
import searchImage from "../assets/images/search.png"
import { EditOutlined, EditTwoTone, EyeOutlined, EyeTwoTone, PlusOutlined } from "@ant-design/icons"
import { addTermsOfService, getTermsOfService } from "../redux/features/terms-of-service/terms-of-service.slice"
import { ITermsOfService } from "../models/terms.model"
import { useNavigate } from "react-router-dom"

const FormItem = Form.Item
const TextArea = Input.TextArea

const TermsOfService = () => {
	const dispatch = useAppDispatch()
	const [visible, setVisible] = useState(false)
	const [newTermsOfService, setNewTermsOfService] = useState<ITermsOfService>({
		title: "",
		sections: [],
	})
	const { termsOfService, count, status } = useAppSelector((state) => state.termsOfService)

	const navigate = useNavigate()

	useEffect(() => {
		dispatch(getTermsOfService())

		return () => {}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const columns = [
		{
			title: "Title",
			dataIndex: "title",
		},
		{
			title: "Sections",
			dataIndex: "sections",
			render: (text: string, record: ITermsOfService) => <span>{record.sections.length}</span>,
		},
		{
			title: "Date Created",
			dataIndex: "created_at",
			render: (text: string) => <span>{formatUCTtoISO(text)}</span>,
		},
		{
			title: "Actions",
			render: (text: string, record: ITermsOfService) => (
				<Space>
					<Tooltip title='View'>
						<Button size='small' type='link' onClick={() => navigate(`/terms/${record._id}`)}>
							<EyeOutlined />
						</Button>
					</Tooltip>
					<Tooltip title='Update'>
						<Button size='small' type='link' onClick={() => navigate(`/terms/edit/${record._id}`)}>
							<EditOutlined />
						</Button>
					</Tooltip>
				</Space>
			),
		},
	]

	const handlePageChange = (page: number, pageSize: number) => {
		dispatch(getTermsOfService())
	}

	useEffect(() => {
		if (status === "Terms of Service created successfully" && visible && newTermsOfService.title.length > 0 && newTermsOfService.sections.length > 0) {
			setNewTermsOfService({
				title: "",
				sections: [],
			})

			setVisible(false)
		}

		return () => {}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [status])

	const handleSubmit = () => {
		if (newTermsOfService.title.length > 0 && newTermsOfService.sections.length > 0) dispatch(addTermsOfService(newTermsOfService))
	}

	// Create a function that generates paragraphs for each section and update sections
	const handleParagraphs = (event: any) => {
		console.log("🚀 ~ file: terms-of-service.tsx:90 ~ handleParagraphs ~ event:", event)
		const paragraphs = event.target.value.split("\n")
		console.log("🚀 ~ file: terms-of-service.tsx:91 ~ handleParagraphs ~ paragraphs:", paragraphs)

		// Delete empty paragraphs
		paragraphs.forEach((paragraph: string, index: number) => {
			if (paragraph.length === 0) paragraphs.splice(index, 1)
		})
		console.log("🚀 ~ file: terms-of-service.tsx:98 ~ paragraphs.forEach ~ paragraphs:", paragraphs)

		setNewTermsOfService({ ...newTermsOfService, sections: paragraphs })
	}

	return (
		<>
			<PageHeader
				className='site-page-header'
				title='Terms'
				subTitle='Manage Terms and Conditions for all your apps'
				extra={[
					<Button key='1' onClick={() => setVisible(true)}>
						<PlusOutlined /> Create New
					</Button>,
				]}></PageHeader>
			<div className='terms-of-service-page page' style={{ minHeight: "81vh" }}>
				<div className='container'>
					<Spin tip={status} spinning={status === "Deleting selected Terms of Service"}>
						{termsOfService.length > 0 ? (
							<Table
								columns={columns}
								dataSource={termsOfService}
								size='small'
								pagination={{
									position: ["bottomRight"],
									onChange: handlePageChange,
									total: count,
									pageSize: termsOfService.length,
								}}
							/>
						) : (
							<div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
								<Empty
									image={searchImage}
									imageStyle={{
										height: 420,
									}}
									description={<h3 style={{ fontWeight: "normal", margin: "2rem 0 0 0" }}>No Terms of Service found</h3>}
								/>
							</div>
						)}
					</Spin>
				</div>
				<Modal
					title='Post a new Terms section'
					centered
					destroyOnClose={true}
					visible={visible}
					onOk={handleSubmit}
					onCancel={() => setVisible(false)}
					width={1000}>
					<Form layout='vertical'>
						<FormItem label='Section Title'>
							<TextArea
								value={newTermsOfService.title}
								rows={1}
								onChange={(event: any) => setNewTermsOfService({ ...newTermsOfService, title: event.target.value })}
							/>
						</FormItem>
						<FormItem label='Paragraphs'>
							<TextArea rows={6} onChange={handleParagraphs} />
						</FormItem>
					</Form>
				</Modal>
			</div>
		</>
	)
}

export default TermsOfService
