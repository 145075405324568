import { configureStore } from '@reduxjs/toolkit';
import { LOCAL_STORAGE_KEY } from '../app-constants';
import authReducer from './features/auth/auth.slice';
import prefReducer from './features/preferences/preferences.slice';
import policiesReducer from './features/policies/policies.slice';
import eftReducer from './features/transactions/eft.slice';
import easypayReducer from './features/transactions/easypay.slice';
import faqsReducer from './features/faqs/faqs.slice';
import termsOfServiceReducer from './features/terms-of-service/terms-of-service.slice';
import gravesReducer from './features/graves/graves.slice';
import settingsReducer from './features/settings/settings.slice';
import claimsReducer from './features/claims/claims.slice';
import documentsReducer from './features/documents/documents.slice';
import signupRequestReducer from './features/signup-requests/signup-requests.slice';
import userReducer from './features/user-manager/user-manager-slice';
import funeralsReducer from './features/funerals/funerals.slice';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    preferences: prefReducer,
    policies: policiesReducer,
    eft: eftReducer,
    easypay: easypayReducer,
    faqs: faqsReducer,
    termsOfService: termsOfServiceReducer,
    graves: gravesReducer,
    settings: settingsReducer,
    users: userReducer,
    claims: claimsReducer,
    documents: documentsReducer,
    signupRequests: signupRequestReducer,
    funerals: funeralsReducer,
  },
});

store.subscribe(() => {
  localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(store.getState()));
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
