import { Col, Row, Statistic, Table, Space, Button, Select, PageHeader } from "antd"
import { useEffect, useState, useRef } from "react"
import { useAppDispatch } from "../redux/hook"
import { getColumnDefinitions } from "../utils/columnHelper"
import { AndyImportTool } from "../components/andyImportTool"
import { clearTransactions } from "../redux/features/transactions/eft.slice"
import * as XLSX from "xlsx" // Import xlsx library

// Define types for entries and buckets
interface Entry {
	MembershipID?: string
	Policy?: string
	TransactionDate: string
	Credit?: number
	Amount?: number
}

interface Buckets {
	assitReceipts: Entry[]
	assitCreditNotes: Entry[]
	easipolEasypayReceipts: Entry[]
}

const STORAGE_KEY = "bucketData"

const ReceiptManagement = () => {
	const dispatch = useAppDispatch()

	const [buckets, setBuckets] = useState<Buckets>({
		assitReceipts: [],
		assitCreditNotes: [],
		easipolEasypayReceipts: [],
	})

	const [filterType, setFilterType] = useState<"all" | "duplicates" | "non-duplicates">("all")
	const [duplicateEntries, setDuplicateEntries] = useState<Record<string, boolean>>({})

	// Track if it's the initial render
	const isInitialMount = useRef(true)

	// Load bucket data from localStorage when the component mounts
	useEffect(() => {
		const savedBuckets = localStorage.getItem(STORAGE_KEY)
		if (savedBuckets) {
			setBuckets(JSON.parse(savedBuckets))
		} else {
			dispatch(clearTransactions()) // Clear transactions if no saved data
		}
	}, [dispatch])

	// Save bucket data to localStorage whenever it changes, but skip the initial render
	useEffect(() => {
		if (isInitialMount.current) {
			isInitialMount.current = false // Mark initial render as done
		} else {
			localStorage.setItem(STORAGE_KEY, JSON.stringify(buckets))
			findCrossDatasetDuplicates()
		}
	}, [buckets])

	// Clear individual bucket data
	const clearBucket = (bucketName: keyof Buckets) => {
		setBuckets((prevBuckets) => {
			const updatedBuckets = { ...prevBuckets, [bucketName]: [] }
			localStorage.setItem(STORAGE_KEY, JSON.stringify(updatedBuckets)) // Update localStorage as well
			return updatedBuckets
		})
	}

	// Helper function to create reference map from Assit Receipts and Assit Credit Notes
	const createReferenceMap = (sourceBuckets: Buckets): Record<string, boolean> => {
		const referenceMap: Record<string, boolean> = {}
		sourceBuckets.assitReceipts.concat(sourceBuckets.assitCreditNotes).forEach((entry) => {
			const identifier = `${entry.MembershipID || entry.Policy}_${entry.TransactionDate}_${entry.Amount}`
			referenceMap[identifier] = true
		})
		return referenceMap
	}

	// Function to find duplicates across datasets, focusing on Easipol Easypay Receipts
	const findCrossDatasetDuplicates = () => {
		const referenceMap = createReferenceMap(buckets)
		console.log("Reference Map =>", referenceMap)
		const easipolDuplicates: Record<string, boolean> = {}

		buckets.easipolEasypayReceipts.forEach((entry) => {
			const identifier = `${entry.MembershipID || entry.Policy}_${entry.TransactionDate}_${entry.Amount}`
			if (referenceMap[identifier]) {
				easipolDuplicates[identifier] = true
				console.log("Identifier found => ", identifier)
			}
		})

		setDuplicateEntries(easipolDuplicates)
	}

	// Filtered Data
	const getFilteredData = (data: Entry[]) => {
		return data.filter((record) => {
			const identifier = `${record.MembershipID || record.Policy}_${record.TransactionDate}_${record.Amount}`
			return filterType === "duplicates" ? duplicateEntries[identifier] : filterType === "non-duplicates" ? !duplicateEntries[identifier] : true
		})
	}

	const getRowClassName = (record: Entry, datasetType: string) => {
		if (datasetType === "easipolEasypayReceipts") {
			const identifier = `${record.MembershipID || record.Policy}_${record.TransactionDate}_${record.Amount}`
			return duplicateEntries[identifier] ? "duplicate-row" : ""
		}
		return ""
	}

	const calculateStats = () => {
		const totalEntries = buckets.assitReceipts.concat(buckets.assitCreditNotes, buckets.easipolEasypayReceipts)
		let duplicateCount = 0
		let nonDuplicateCount = 0

		totalEntries.forEach((entry) => {
			const identifier = `${entry.MembershipID || entry.Policy}_${entry.TransactionDate}_${entry.Amount}`
			duplicateEntries[identifier] ? duplicateCount++ : nonDuplicateCount++
		})

		return { duplicateCount, nonDuplicateCount }
	}

	const { duplicateCount, nonDuplicateCount } = calculateStats()

	// Export non-duplicate Easipol data to XLSX
	const exportNonDuplicateEasipolData = () => {
		const nonDuplicateData = getFilteredData(buckets.easipolEasypayReceipts).filter(
			(record) => !duplicateEntries[`${record.MembershipID || record.Policy}_${record.TransactionDate}_${record.Credit || record.Amount}`]
		)

		const worksheet = XLSX.utils.json_to_sheet(nonDuplicateData)
		const workbook = XLSX.utils.book_new()
		XLSX.utils.book_append_sheet(workbook, worksheet, "Non-Duplicate Easipol Receipts")

		// Export as Excel file
		XLSX.writeFile(workbook, "Non-Duplicate_Easipol_Receipts.xlsx")
	}

	return (
		<>
			<PageHeader className='site-page-header' title='Receipt Management' extra={[]}>
				<Row gutter={16} style={{ padding: "2rem" }}>
					<Col span={14} style={{ display: "flex", justifyContent: "flex-start" }}>
						<Space size={32}>
							<Statistic title='Assit Receipts' value={buckets.assitReceipts.length || 0} />
							<Statistic title='Assit Credit Notes' value={buckets.assitCreditNotes.length || 0} />
							<Statistic title='Easipol Easypay Receipts' value={buckets.easipolEasypayReceipts.length || 0} />
							<Statistic title='Duplicate Entries (Easipol)' value={duplicateCount} />
							<Statistic title='Non-Duplicate Entries' value={nonDuplicateCount} />
						</Space>
					</Col>
					<Col span={6} style={{ display: "flex", justifyContent: "flex-end" }}>
						<Space>
							<AndyImportTool buckets={buckets} setBuckets={setBuckets} />
						</Space>
					</Col>
					<Col span={4} style={{ display: "flex", justifyContent: "flex-end" }}>
						<Space>
							<Select defaultValue='all' onChange={(value: "all" | "duplicates" | "non-duplicates") => setFilterType(value)} style={{ width: 200 }}>
								<Select.Option value='all'>All Entries</Select.Option>
								<Select.Option value='duplicates'>Only Duplicates</Select.Option>
								<Select.Option value='non-duplicates'>Non-Duplicates</Select.Option>
							</Select>
							{filterType == "non-duplicates" && (
								<Button type='primary' onClick={exportNonDuplicateEasipolData}>
									Export
								</Button>
							)}
						</Space>
					</Col>
				</Row>
			</PageHeader>

			<div className='receipt-management-page page' style={{ minHeight: "67vh" }}>
				<div className='container'>
					<div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
						<h3>Assit Receipts</h3>
						<Button type='primary' danger onClick={() => clearBucket("assitReceipts")}>
							Clear
						</Button>
					</div>
					<Table
						dataSource={getFilteredData(buckets.assitReceipts)}
						columns={getColumnDefinitions("assitReceipts")}
						pagination={{ pageSize: 5 }}
						rowClassName={(record) => getRowClassName(record, "assitReceipts")}
					/>

					<div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "1rem" }}>
						<h3>Assit Credit Notes</h3>
						<Button type='primary' danger onClick={() => clearBucket("assitCreditNotes")}>
							Clear
						</Button>
					</div>
					<Table
						dataSource={getFilteredData(buckets.assitCreditNotes)}
						columns={getColumnDefinitions("assitCreditNotes")}
						pagination={{ pageSize: 5 }}
						rowClassName={(record) => getRowClassName(record, "assitCreditNotes")}
					/>

					<div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "1rem" }}>
						<h3>Easipol Easypay Receipts</h3>
						<Button type='primary' danger onClick={() => clearBucket("easipolEasypayReceipts")}>
							Clear
						</Button>
					</div>
					<Table
						dataSource={getFilteredData(buckets.easipolEasypayReceipts)}
						columns={getColumnDefinitions("easipolEasypayReceipts")}
						pagination={{ pageSize: 5 }}
						rowClassName={(record) => getRowClassName(record, "easipolEasypayReceipts")}
					/>
				</div>
			</div>
		</>
	)
}

export default ReceiptManagement
