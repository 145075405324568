import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { CLIENT_SERVICE_URL } from '../../../app-constants';
import { IReferenceList } from '../../../models/reference-list.model';
import { getAuthHeader } from '../../helpers';

// Define a type for the slice state
interface IState {
  referenceLists: IReferenceList[];
  status: string;
}

// Define the initial state using that type
const initialState: IState = {
  referenceLists: [],
  status: 'idle',
};

export const addList = createAsyncThunk('settings/addList', async (list: IReferenceList, thunkAPI) => {
  const dispatch = thunkAPI.dispatch;

  try {
    const response = await axios.post(`${CLIENT_SERVICE_URL}reference-lists`, list, {
      headers: getAuthHeader(),
    });

    if (response.status === 200) {
      dispatch(getReferenceLists());
      return response.data;
    } else {
      console.log(response);
      return false;
    }
  } catch (error) {
    console.error(error);
    return false;
  }
});

export const getReferenceLists = createAsyncThunk('settings/getReferenceLists', async () => {
  try {
    const response = await axios.get(`${CLIENT_SERVICE_URL}reference-lists`, {
      headers: getAuthHeader(),
    });

    if (response.status === 200) {
      return response.data;
    } else {
      console.log(response);
      return false;
    }
  } catch (error) {
    console.error(error);
    return false;
  }
});

// export const searchFAQs = createAsyncThunk('faqs/search', async (text: string) => {
//   try {
//     const response = await axios.post(
//       `${CLIENT_SERVICE_URL}faqs/search`,
//       { searchText: text },
//       {
//         headers: getAuthHeader(),
//       }
//     );

//     if (response.status === 200) {
//       return response.data;
//     } else {
//       console.log(response);
//       return false;
//     }
//   } catch (error) {
//     console.error(error);
//     return false;
//   }
// });

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    hydrateSettings(state: any, action: any) {
      state.referenceLists = action.payload.referenceLists;
    },
  },
  extraReducers: (builder: any) => {
    builder
      .addCase(getReferenceLists.pending, (state: any) => {
        state.status = 'Fetching Reference Lists';
      })
      .addCase(getReferenceLists.rejected, (state: any) => {
        state.status = 'idle';
      })
      .addCase(getReferenceLists.fulfilled, (state: any, action: any) => {
        state.status = 'idle';

        let updatedItems = [...action.payload];
        updatedItems.map((item) => (item.key = item._id));

        state.referenceLists = updatedItems;
      })
      .addCase(addList.pending, (state: any) => {
        state.status = 'Adding Reference Lists';
      })
      .addCase(addList.rejected, (state: any) => {
        state.status = 'idle';
      })
      .addCase(addList.fulfilled, (state: any, action: any) => {
        state.status = 'idle';

        // let updatedItems = [...action.payload];
        // updatedItems.map((item) => (item.key = item._id));

        // state.referenceLists = updatedItems;
      });
  },
});

export const { hydrateSettings } = settingsSlice.actions;

export default settingsSlice.reducer;
