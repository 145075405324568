import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { CLIENT_SERVICE_URL } from '../../../app-constants';
import { sortList } from '../../../utils/helpers';
import { getAuthHeader } from '../../helpers';

// Define a type for the slice state
interface IState {
  documents: any[];
  selectedDocument: any;
  status: 'idle' | 'pending' | 'succeeded' | 'failed';
}

// Define the initial state using that type
const initialState: IState = {
  documents: [],
  selectedDocument: {},
  status: 'idle',
};

export const addDocument = createAsyncThunk('documents/create', async ({ data, file }: any, thunkApi) => {
  const dispatch = thunkApi.dispatch;

  try {
    const response = await axios.post(`${CLIENT_SERVICE_URL}documents`, data);

    if (response.status === 200 || response.status === 201) {
      const document = response.data;

      dispatch(uploadDocumentFile({ id: document._id, file }));

      return document;
    } else {
      console.log(response);
      return false;
    }
  } catch (error) {
    console.error(error);
    return false;
  }
});

export const uploadDocumentFile = createAsyncThunk('documents/upload', async ({ id, file }: any) => {
  try {
    const formData = new FormData();

    formData.append('file', file);

    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };

    const response = await axios.post(`${CLIENT_SERVICE_URL}documents/${id}/upload`, formData, config);

    if (response.status === 200 || response.status === 201) {
      return response.data;
    } else {
      console.log(response);
      return false;
    }
  } catch (error) {
    console.error(error);
    return false;
  }
});

export const listDocuments = createAsyncThunk('documents/list', async () => {
  try {
    const response = await axios.get(`${CLIENT_SERVICE_URL}documents`, {
      headers: getAuthHeader(),
    });

    if (response.status === 200) {
      return response.data;
    } else {
      console.log(response);
      return false;
    }
  } catch (error) {
    console.error(error);
    return false;
  }
});

export const getDocument = createAsyncThunk('documents/get', async (id: string) => {
  try {
    const response = await axios.get(`${CLIENT_SERVICE_URL}documents/${id}`, {
      headers: getAuthHeader(),
    });

    if (response.status === 200) {
      console.log(response);
      return response.data;
    } else {
      console.log(response);
      return false;
    }
  } catch (error) {
    console.error(error);
    return false;
  }
});

export const deleteDocument = createAsyncThunk('documents/remove', async (id: string) => {
  try {
    const response = await axios.delete(`${CLIENT_SERVICE_URL}documents/${id}`, {
      headers: getAuthHeader(),
    });

    if (response.status === 200) {
      console.log(response);
      return id;
    } else {
      console.log(response);
      return false;
    }
  } catch (error) {
    console.error(error);
    return false;
  }
});

export const documentsSlice = createSlice({
  name: 'documents',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    hydrateDocuments(state: any, action: any) {
      state.documents = action.payload.documents;
    },
  },
  extraReducers: (builder: any) => {
    builder
      .addCase(deleteDocument.pending, (state: any) => {
        state.status = 'pending';
      })
      .addCase(deleteDocument.rejected, (state: any, payload: any) => {
        state.status = 'failed';
      })
      .addCase(deleteDocument.fulfilled, (state: any, action: any) => {
        state.status = 'succeeded';

        let updatedList = [...state.documents];

        const index = updatedList.findIndex(e => e._id === action.payload);

        updatedList.map((item) => (item.key = item._id));
        updatedList.splice(index, 1);

        let sortedList = sortList(updatedList);
        state.documents = sortedList;
      })
      .addCase(uploadDocumentFile.pending, (state: any) => {
        state.status = 'pending';
      })
      .addCase(uploadDocumentFile.rejected, (state: any, payload: any) => {
        state.status = 'failed';
      })
      .addCase(uploadDocumentFile.fulfilled, (state: any, action: any) => {
        state.status = 'succeeded';

        let updatedList = [...state.documents, action.payload];
        updatedList.map((item) => (item.key = item._id));

        let sortedList = sortList(updatedList);
        state.documents = sortedList;
      })
      .addCase(getDocument.pending, (state: any) => {
        state.status = 'pending';
      })
      .addCase(getDocument.rejected, (state: any, payload: any) => {
        state.status = 'failed';
      })
      .addCase(getDocument.fulfilled, (state: any, action: any) => {
        state.status = 'succeeded';

        state.selectedDocument = action.payload;
      })
      .addCase(listDocuments.pending, (state: any) => {
        state.status = 'pending';
      })
      .addCase(listDocuments.rejected, (state: any, payload: any) => {
        state.status = 'failed';
      })
      .addCase(listDocuments.fulfilled, (state: any, action: any) => {
        state.status = 'succeeded';

        let updatedList = [...action.payload];
        updatedList.map((item) => (item.key = item._id));

        let sortedList = sortList(updatedList);
        state.documents = sortedList;
      });
  },
});

export const { hydrateDocuments } = documentsSlice.actions;

export default documentsSlice.reducer;
