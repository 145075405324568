import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { CLIENT_SERVICE_URL } from '../../../app-constants';
import { getAuthHeader } from '../../helpers';
import IFuneral from '../../../models/funeral.model';

// Define a type for the slice state
interface IState {
  funerals: IFuneral[];
  selectedFuneral: IFuneral | undefined;
  status: string;
}

// Define the initial state using that type
const initialState: IState = {
  funerals: [],
  selectedFuneral: undefined,
  status: 'idle',
};

export const getFunerals = createAsyncThunk('funerals/fetch', async () => {
  try {
    const response = await axios.get(`${CLIENT_SERVICE_URL}fb/funerals`, {
      headers: getAuthHeader(),
    });

    if (response.status === 200) {
      return response.data.funerals;
    } else {
      console.log(response);
      return false;
    }
  } catch (error) {
    console.error(error);
    return false;
  }
});

export const funeralsSlice = createSlice({
  name: 'funerals',
  initialState,
  reducers: {
    hydrateFunerals(state: any, action: any) {
      state.referenceLists = action.payload.referenceLists;
    },
    setSelectedFuneral(state: any, action: any) {
      const funeralId = action.payload;

      const funeral = state.funerals.find((e: IFuneral) => e.id === funeralId)

      if (funeral) {
        state.selectedFuneral = funeral;
      }
    },
    deselectFuneral(state: any) {
      state.selectedFuneral = undefined
    }
  },
  extraReducers: (builder: any) => {
    builder
      .addCase(getFunerals.pending, (state: any) => {
        state.status = 'Fetching Funerals';
      })
      .addCase(getFunerals.rejected, (state: any) => {
        state.status = 'idle';
      })
      .addCase(getFunerals.fulfilled, (state: any, action: any) => {
        state.status = 'idle';

        const funerals = action.payload.map((funeral: IFuneral) => {
          return {
            title: `${funeral.deceased_name} ${funeral.deceased_surname}`,
            date: new Date(`${funeral.burial_date_time}`).getTime(),
            key: funeral._id,
            ...funeral,
          }
        })

        state.funerals = funerals;
      });
  },
});

export const { hydrateFunerals, setSelectedFuneral, deselectFuneral } = funeralsSlice.actions;

export default funeralsSlice.reducer;
